.TeamInformationForm {
    button {
        max-width: 180px;
    }
    .icon_button{
        width: 40px;
        height: 40px;
    }
    form {
        .SelectFormInput.status{
            width: 100px;
        }
        .TextFormInput{
            margin-bottom: 14px;
            .MuiFormControl-root {
                min-width: 400px;
            }
        }
    }
    @media (max-width: 570px) {
        margin-left: 8px;
        margin-right: 8px;
        form {
            .TextFormInput{
                margin-right: 4px;
                max-width: 90vw;
                overflow: hidden;
                div {
                    max-width: 90vw;
                }
            }
            .MultiSelectFormInput-wrapper {
                width: 92%;
            }
            .SelectFormInput.currency, .SelectFormInput.country, .SelectFormInput.state{
                width: 92%;
            }
        }
    }
}
