.GymMemberships {
    .filter {
        display: flex;
        flex-direction: column;
        justify-content: start;
        gap: 10px;
        .button {
            max-width: 260px;
        }
    }
    .merge-option, .member {
        display: flex;
        flex-direction: row; 
        align-items: center;
    }
    .MergeMemberhips {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        border: 1px solid gray;
        padding: 10px;
        border-radius: 4px;
        .merge-info, .buttons {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            margin-right: 20px;
            .merge-icon {
                color: green;
                transform: rotate(90deg);
                width: 40px;
                height: 40px;
                cursor: pointer;
            } 
            .merge-icon.reverse {
                transform: rotate(270deg);
            }
        }
    }
    
    .memberships {
        display: flex;
        flex-wrap: wrap;
        .membership {
            margin-bottom: 20px;
        }
    }
    
    @media (max-width: 575px) {
        margin-left: 8px;
        margin-right: 8px;
        .filter {
            flex-direction: column;
            align-items: unset;
            .TextFormInput.filter{
                display: flex;
                .MuiFormControl-root {
                    width: 100%;
                    max-width: unset;
                    min-width: unset;
                }
            }
        }
    }
}