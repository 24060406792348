.TransferList {
    position: relative;
    .CloseIcon {
        position: absolute;
        right: 0px;
        cursor: pointer;
        background-color: var(--ion-color-primary);
        border-radius: 50%;
    }
    .MuiGrid-root {
        width: 100%;
    }
    .CustomList {
        width: 100%;
        .ListItem {
            span {
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                vertical-align: top;
            }
        }
        .title {
            text-decoration: underline;
            margin-left: 10px;
            margin-bottom: 10px;
        }
        .header-filter {
            margin-top: 14px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 20px;
            .title-wrapper {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;
                .inFilter {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 10px;
                    .MuiTextField-root {
                        margin-left: 20px;
                        width: 300px;
                    }
                }
            }
        }
        .select-all {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 16px;
            .count {
                font-size: small;
                font-style: italic;
                .SelectMessage{
                    text-decoration: underline;
                    color: blue;
                    margin-left: 10px;
                }
            }
        }
    }
    .buttons {
        display: flex;
        width: 100%;
        justify-content: center;
        gap: 4px;
        margin-top: 10px;
    }

    @media (max-width: 575px) {
        .buttons {
            justify-content: start;
            margin-left: 20px;
        }
        .CustomList {
            .header-filter {
                flex-direction: column;
                align-items: flex-start;
                .title-wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    .extFilter, .inFilter {
                        width: 240px;
                    }
                    .inFilter {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: start;
                        .MuiTextField-root {
                            margin-left: 0px;
                        }
                    }
                    .title {
                        flex-direction: row;
                        align-items: center;
                        span {
                            margin-right: 10px;
                        }
                    }
                    .MuiTextField-root {
                        margin-left: 0px;
                        width: 100%;
                    }
                }
            }
        }
    }
}