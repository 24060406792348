*,*:before,*:after {
  box-sizing:border-box;
}

body {
  font-size: 20px;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
  margin: 0 0 0em;
}

.buttons, ion-buttons {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 6px;
}
.buttons.grid {
  display: grid;
}

span.button {
  cursor: pointer;
}
button.button, span.button {
  opacity: .78;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 1em 2em 1em 1.5em;
  border-radius: 8px;
  font-size: 0.9em;
  background: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  font-weight: bold;
  min-width: 6em;
  max-height: 40px;
  border: 1px solid var(--ion-border-color);
  gap: 10px;
  .icon {
    filter: invert(1);
  }


  i {
    margin-right: 0.5em;
  }

  &[disabled] {
    opacity: 0.3;
    cursor: not-allowed;
  }
  &.alt_button {
    background: transparent;
    border: 2px solid var(--ion-color-primary);
    //color: var(--ion-color-primary);
  }
  &:hover {
    opacity: 1;
  }
  &:disabled:hover {
    opacity: 0.3;
  }
}

button.warning {
  background: var(--ion-color-warning);
  color: var(--ion-color-warning-contrast);
}

button.small_button, button.icon_button, span.icon_button{
  display: flex;
  align-items: center;
  margin-right: 6px;
  margin: 0;
  white-space: nowrap;
  padding: 0.5em;
  border-radius: 8px;
  font-size: 0.9em;
  background: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  font-weight: bold;
  min-width: 6em;
  border: 1px solid var(--ion-border-color);
}

button.icon_button, span.icon_button {
  margin: 0;
  min-width: 1em;

  .icon {
    height: 2em;
    width: 2em;
    filter: invert(1);
    margin: 0;
  }
}

.css-1vrqiqu-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  border-radius: 10px;
}

.css-nlxmqn-MuiFormControl-root {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.MuiSelect-root__label {
  margin-right: 1em;
}

.MuiSelect-root {
  background: transparent;
  border: 1px solid var(--ion-border-color);
  background: var(--module-background-color);
  padding: 1em 3em 1em 1em;
  font-size: 1em;
  border-radius: 10px;
  position: relative;
  color: inherit;
  min-width: 15em;
  text-align: left;
  &:after {
    content: '';
    background: url("../../public/assets/icon/caret-down.svg");
    position: absolute;
    right: 0;
    margin-right: 1em;
    height: 1.5em;
    width: 1.5em;
    background-size: contain;
    top: calc(50% - 0.78em);
    filter: invert(1);
  }
}

.MuiInput-input {
  border: 1px solid var(--ion-border-color);
  background: var(--module-background-color);
  padding: 0.75em 1em;
  font-size: 1em;
  border-radius: 10px;
  min-width: 15em;
  &:focus {
    outline: none;
  }
}

.MuiOption-root.Mui-disabled {
  display: none;
}

.MuiSelect-listbox {
  margin: 0.5em 0;
  padding: 0;
  background: var(--module-background-color);
  color: var(--ion-text-color);
  border-radius: 10px;
  list-style-type: none;
  cursor: pointer;
  max-height: 300px;
  overflow: auto;
  .MuiOption-root {
    padding: 0.5em 3em 0.5em 1em;
    &:hover {
      background: var(--ion-color-tertiary);
      color: white;
    }
  }
}

.entity-type {
  font-size: xx-small;
}

.small-font {
  font-size: 0.6em;
}


/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #758493;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--ion-color-primary);
}

#entity_filter {
  min-width: 300px;
}

.inverse-color {
  filter: invert(100%) brightness(0%);
}

.object-tag {
  font-size: small;
  font-style: italic;
}

.flex-column {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.flex-column.gap {
  gap: 20px;
}

.table-border {
  border: 1px solid var(--ion-border-color);
  padding: 20px;
  border-radius: 10px;
  background: #f1f1f1;
}

.text-readOnly {
  background: '#f0f0f0';
  color: '#333';
}

.sticky {
  background-color: aliceblue;
  z-index: 100;
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0; /* Stick to the top of the viewport when scrolling down */
  border-bottom: 1px solid black;
}

.search-wrapper {
  background-color: aliceblue;
  max-width: 300px;
  padding: 10px;
  border-radius: 4px;
  .MuiTextField-root {
    width: 100%;
  }
}

.darkened {
  filter: brightness(0.6);
}

.page-title {
  position: sticky;
  top: 0;
  z-index: 100;
  opacity: 1;
  background-color: white;
  border-bottom: 1px solid gray;
  margin-bottom: 20px;
}

ion-content {
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0px;
  }
  .ion-page {
    padding: 4px;
  }
}

.FullScreenIcon {
  position: absolute;
  z-index: 100;
  color: blue;
  right: 2px;
  cursor: pointer;
}

.Link {
  cursor: pointer;
}