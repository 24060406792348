.loadingName {
  width: 30vw;
}

.loadingDate {
  width: 15vw;
}

.loadingLocation {
  width: 10vw;
}

.loadingImage {
  width: 5vw;
  height: 5vw;
  margin: 1rem 0;
}