.Profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
    h2 {
        margin: 20px;
        text-decoration: underline;
    }
    .MergeUser {
        z-index: 100;
        max-width: 600px;
        margin-bottom: 60px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .merge {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            button {
                margin-bottom: -14px;
            }
        }
        .note {
            font-size: small;
            font-style: italic;
            margin-left: 10px;
            text-decoration: underline;
        }
    }
    .form-wrapper {
        display: flex;
        padding: 20px;
        max-width: 640px;
        flex-direction: column;
        justify-content: center;
        form {
            margin-top: 10px;
            .gym-list-wrapper {
                ol.gym-list {
                    li.gym {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 10px;
                    }
                }
            }
            .note {
                font-size: smaller;
                color: blue;
                font-style: italic;
                margin-left: 10px;
            }
            .AddEntity {
                display: flex;
                flex-wrap: wrap;
                padding: 10px;
                gap: 10px;
                .title {
                    color: blue;
                }
                .require {
                    color: red;
                }
                .AddressForm {
                    width: 100%;
                }
            }
            .username-wrapper {
                display: flex;
                align-items: center;
                position: relative;
                .check-username {
                    position: absolute;
                    right: 4px;
                    bottom: 1px;
                    z-index: 10;
                    font-size: x-small;
                    .CheckUserNameIcon {
                        text-decoration: underline;
                        cursor: pointer;
                        display: block;
                        white-space: nowrap;
                        color: blue;
                    }
                }
            }
            .Coaches {
                .coach-entry {
                    margin-bottom: 20px;
                    display: grid;
                    .coach-wrapper {
                        display: flex;
                        flex-direction: row;
                        margin-bottom: 12px;
                        .coach {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            .coach-member {
                                width: 400px;
                            }
                        }
                    }
                }
            }
            .Families {
                .family-member-wrapper {
                    // border: 1px solid rgba(128, 128, 128, 0.15);
                    padding: 8px;
                    border-radius: 8px;
                    margin-bottom: 20px;
                    .name-label {
                        font-size: small;
                        font-weight: bold;
                        text-decoration: underline;
                        color: blue;
                        text-align: right;
                    }
                    .entry {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        .family-member {
                            width: 300px;
                        }
                        .SelectFormInput.relation {
                            width: 200px;
                        }
                    }
                }
                @media (max-width: 575px) {
                    .family-member-wrapper{
                        width: 96%;
                        padding: 4px;
                        .entry {
                            display: grid;
                            gap: 8px;
                            .family-member, .SelectFormInput{
                                width: 90%;
                            }
                        }
                    }
                }
            }
            .SelectFormInput.status{
                width: 140px;
            }
            .TextFormInput{
                margin-bottom: 14px;
                .MuiFormControl-root {
                    min-width: 400px;
                }
            }
            .TextFormInput-wrapper.weight, .TextFormInput-wrapper.age, .TextFormInput-wrapper.day {
                width: 100px;
                .ClearIcon {
                    display: none;
                }
            }
            .division-wrapper {
                .profile {
                    width: 100%;
                    display: flex;
                    align-items: flex-end;
                    .SelectFormInput.no_gi_level {
                        margin-bottom: 0px;
                        width: 200px;
                    }
                    .SelectFormInput.gi_level {
                        margin-bottom: 0px;
                        width: 160px;
                    }
                    .SelectFormInput.weight_unit{
                        width: 80px;
                    }
                    .weight-wrapper {
                        margin-bottom: 0px;
                        div {
                            margin-bottom: 0px;
                        }
                        .TextFormInput.weight{
                            .MuiFormControl-root {
                                margin-top: inherit;
                                margin-bottom: inherit;
                                max-width: 100px;
                                min-width: 100px;
                            }
                        }
                    }
                }
                .age {
                    display: flex;
                    .TextFormInput{
                        margin-top: 4px;
                        margin-bottom: 0px;
                        margin-right: 0px;
                        .MuiFormControl-root {
                            margin-top: inherit;
                            margin-bottom: inherit;
                            max-width: 80px;
                            min-width: 80px;
                        }
                    }
                }
                .next-age-update {
                    display: flex;
                    flex-direction: row;
                    position: relative;
                    align-items: flex-end;
                    margin-top: 30px;
                    .label {
                        position: absolute;
                        top: -24px;
                        font-size: .8rem;
                        color: rgba(0, 0, 0, 0.6);
                    }
                    .SelectFormInput {
                        max-width: 140px;
                        .MuiFormControl-root {
                            margin-top: 10px !important;
                        }
                    }
                    .TextFormInput{
                        margin-top: 4px;
                        margin-bottom: 0px;
                        margin-right: 0px;
                        .MuiFormControl-root {
                            margin-top: inherit;
                            margin-bottom: inherit;
                            max-width: 80px;
                            min-width: 80px;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 575px) {
        padding: 4px;
        .form-wrapper {
            width: 100%;
            margin-left: 24px;
            margin-right: 24px;
            form {
                display: flex;
                flex-direction: column;
                .username-wrapper {
                    width: 90%;
                }
                .MultiSelectFormInput {
                    width: 90%;
                }
                .MuiFormControl-root {
                    min-width: unset;
                    width: 100% !important;
                    .MuiOutlinedInput-root{
                        width: 100%;
                    }
                }
                .Coaches {
                    .coach-entry {
                        .coach-wrapper {
                            .coach {
                                flex-direction: column;
                                width: 100%;
                                .SelectFormInput, .coach-member {
                                    width: 70%;
                                    margin-bottom: 12px;
                                }
                            }
                        }
                    }
                }
                .TextFormInput{
                    margin-right: 4px;
                    max-width: 94vw;
                    overflow: hidden;
                    div {
                        max-width: 94vw;
                    }
                    .MuiFormControl-root {
                        min-width: unset;
                    }
                }
                .division-wrapper {
                    .profile {
                        display: flex;
                        flex-wrap: wrap;
                        .SelectFormInput.no_gi_level {
                            margin-bottom: 10px;
                            width: 90%;
                        }
                        .SelectFormInput.gi_level {
                            margin-bottom: 10px;
                            width: 90%;
                        }
                        .SelectFormInput.gender {
                            width: 40%;
                        }
                        .SelectFormInput.weight_unit{
                            width: 80px;
                            .MuiFormControl-root {
                                margin-top: 12px !important;
                            }
                        }
                    }
                    .age {
                        .SelectFormInput {
                            margin-bottom: 4px;
                            .MuiFormControl-root {
                                margin-top: 12px !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

.AddEntity {
    background-color: rgba(255, 255, 0, 0.082);
    border-radius: 8px;   
    border: 1px solid gray;
}

.ion-content {
   
}