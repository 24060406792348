.PageRequestButtons {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 575px) {
        flex-direction: column;
        button {
            margin-bottom: 4px;
        }
    }
}