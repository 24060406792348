.Rankings {
  margin-top: 1em;
  position: relative;
  .fullscreen {
    padding: 20px;
    background-color: lightgray;
  }
  .teams, .individuals {
    margin-top: 20px;
  }
  .individuals {
    .table {
      .points {
        text-align: center;
      }
      .fieldFlow {
        display: flex;
        align-items: center;
        .name {
          margin-left: 4px;
        }
      }
    }
  }
  h3 {
    margin-bottom: 0.5em;
  }
  .divisionRankingTable {
  }
  
  .divisionRankingRowHeader {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 1fr;
    padding: 1em 1.5em;
    font-size: 90%;
    opacity: 0.5;
    span {
      padding: 10px;
    }
  }
  
  .divisionRankingRow {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 1fr;
    background: var(--module-background-color);
    padding: 1em 1.5em;
    border-radius: 1em;
    margin-bottom: 1em;
    span {
      padding: 10px;
    }
  }
  
  .divisionRankingRowFirst {
    span {
      padding: 10px;
    }
  }
  
  .divisionRankingTableHeader {
    
  }
  
}


