.TableFormInput {
    border: 1px gray solid;
    padding: 10px;
    border-radius: 4px;
    position: relative;
    .Pagination {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: right;
        .TablePagination {
            .MuiTablePagination-toolbar {
                padding-left: 0px;
                .MuiTablePagination-actions {
                    display: flex;
                    flex-direction: row;
                    button {
                        width: 40px;
                    }
                }
            }
        }
    }
    table {
        tr {
            td.index {
                width: 40px;
                height: 100%;
                text-align: left;
                .IndexCell {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-items: center;
                    padding: 20px;
                    gap: 6px;
                    .CloneIcon, .EditIcon {
                        color: blue;
                        cursor: pointer;
                    }
                }
            }
            td.css-ani8uw-MuiTableCell-root.MuiTableCell-paddingCheckbox {
                width: unset;
            }
            td{
                .EditIcon {
                    cursor: pointer;
                    color: blue;
                }
            }
        }
        tr.highlite {
            background-color: var(--ion-color-primary);
        }
    }

    .EnhancedTableToolbar {
        display: flex;
        flex-direction: column;
        .selected-count {
            text-align: right;
            color: blue;
            font-size: small;
        }
        @media screen and (max-width: 575px) {
            flex-wrap: wrap;
            width: 100%;
        }
    }
    .sort-indicator{
        font-size: small;
        font-style: italic;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: blue;
        text-decoration: underline;
        .field {
            display: flex;
            align-items: center;
            svg {
                width: 12px;
            }
        }
        .ResetIcon {
            width: 20px;
            margin-right: 10px;
            cursor: pointer;
        }
    }
    .ToolbarButtons {
        display: flex;
        cursor: pointer;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 4px;
        button {
            margin: 0px;
        }
        button.yellow {
            background-color: yellow;
            border: 1px solid black;
        }
        @media screen and (max-width: 575px) {
            flex-wrap: wrap;
            width: 100%;
        }
    }
    .SelectFormInput {
        .MuiSelect-select {
            text-align: left;
        }
    }
    .table-busy {
        width: 100%;
        .IonSpinner {
            width: 100%;
        }
    }
    .TableHead {
        tr.MuiTableRow-head {
            .checkbox {
                position: relative;
                .spinner {
                    margin-bottom: 10px;
                    position: absolute;
                }
            }
            th {
                font-weight: bold;
                .TableSortHeader {
                    display: flex;
                    align-items: center;
                    svg {
                        cursor: pointer;
                    }
                    .NoSortIcon {
                        opacity: .12;
                    }
                    .DescIcon, .AcsIcon {
                        width: 16px;
                        height: 16px;
                        border: 1px blue solid;
                        border-radius: 50%;
                    }
                    .header-label {
                        display: flex;
                    }
                    .header-label.sorting {
                        color: blue;
                    }
                    .filter-input {
                        position: relative;
                        .textinput-label {
                            z-index: 101 !important;
                            margin-top: 0px;
                            display: flex;
                            flex-direction: row;
                            gap: 6px;
                            align-items: center;
                            .close-icon {
                                left: 4px;
                                color: blue;
                                width: 20px;
                                height: 20px;
                                cursor: pointer;
                            }
                        }
                        
                    }
                }
            }
        }
    }
    .TableRow.is-add {
        background-color: aliceblue;
    }
    .TableContainer {
        .FilterChips-wrapper {
            display: grid;
            // width: max-content;
            min-width: unset;
            .buttons {
                display: flex;
                flex-direction: row;
                width: 100%;
            }
            .FilterChips {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 14px;
                margin-top: 10px;
            }
        }
    }
}