.tabs-wrapper {
  height: 100%;
}
.Tabs {
  // max-width: var(--page-max-width);
  padding: 20px;
  margin: 20px;
  min-height: 100%;
  .tabsWrapper {
    position: relative;
    display: flex;
    gap: 1em;
    margin: 1em auto;
    padding: 0 1em;
    overflow-x: auto;
    overflow-y: hidden;
    min-height: 100%;
    .scroll-left, .scroll-right {
      background-color: var(--ion-background-color);
      position: sticky;
      visibility: hidden;
      top: 8px;
      color: green;
      border: 1px green solid;
      border-radius: 50%;
    }
    .scroll-left.show {
      visibility: initial;
      left: -24px;
    }
    .scroll-right.show {
      visibility: initial;
      right: -24px;
    }
    .tab {
      border-bottom: 1px solid grey;
      cursor: pointer;
      position: relative;
      padding: 0.5em 0;
      font-weight: bold;
      opacity: 0.5;
      white-space: nowrap;
      &.active {
        //color: var(--ion-color-primary);
        opacity: 1;
        color: blue;
        &:after {
          content: '';
          position: absolute;
          bottom: -1.5px;
          left: 0;
          width: 100%;
          height: 0px;
          background-color: var(--ion-text-color);
        }
      }

    }
    //&:after {
    //  content: '';
    //  position: absolute;
    //  width: 100%;
    //  height: 1px;
    //  background: rgba(0,0,0,0.3);
    //  bottom: 0;
    //  z-index: 1;
    //}

  }
  .tabSection {
    padding: .1em;
    min-height: 100%;
    
    .tab-busy {
      display: flex;
      width: 100%; 
      min-height: 600px;
      justify-content: center;
      cursor: wait;
    }
  }

  @media (max-width: 575px) {
    padding: 2px;
    margin: 2px;
    .tabSection {
        padding: 0em;
        .tab-busy {
          min-height: 300px;
        }
    }
  }
}
  
  
  