
.DashboardPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  margin: 0 auto 10vh;
  .Tabs {
    max-width: 80%;
    min-width: 680px;
  }
  .HelpSetup {
    .HelpSetup-table {
      svg {
        cursor: pointer;
      }
      .Help {
        .index-cell {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        td {
          .LocalFiles {
            .images {
              .image-name {
                display: flex;
                flex-direction: row;
                align-items: flex-start;

                gap: 10px;
                svg {
                  border: 1px solid gray;
                  border-radius: 50%;
                  width: 16px;
                  height: 16px;
                }
              }
            }
            .new-file {
              color: green;
            }
          }
        }
      }
    }
  }
  .count0-message {
    color: blue;
    margin-top: 20px;
  }
  .MySMessages {
    max-height: 480px;
    overflow-y: auto;
    ul {
      li {
        cursor: pointer;

      }
    }
  }
  .MyTournaments {
    grid-column: span 4;
    @media screen and (max-width: 575px) {
      grid-column: span 1;
    }
  }
  .MyLeagues, .MyTournaments, .MyTeams, .MyGyms {
    .add {
      width: 100%;
      display: flex;
      justify-content: right;
    }
  }

  .MyGyms {
    .table {
      .tableRow {
        .buttons {
          display: flex;
          flex-direction: row;
          gap: 10px;
          align-items: center;
        }
      }
    }
  }

  .header {
    display: flex;
    .title {
      margin: 0 0 2rem 0;
      max-width: 1000px;
    }
    .profileImage {
      max-height: 60px;
      img {
        object-fit: cover;
      }
    }
  }

  .organization {
    display: flex;
    flex-direction: column;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;

    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
    }
  }
  @media screen and (max-width: 575px) {
    padding: 2px;
    .Tabs {
      max-width: 100%;
      min-width: 100%;
    }
    .ClaimProfiles {
      .filter {
        flex-direction: column;
        div {
          width: 100%;
        }
      }
    }
  }

  .module {
    background: var(--module-background-color);
    border-radius: var(--border-radius);
    box-sizing: border-box;
    min-height: 80;
    max-height: 80;
    overflow-y: auto;
    padding: 1.5rem 1rem;
  }

  .moduleNameWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
  }

  .moduleName {
    font-size: 1em;
    padding: 0 0.5em;
  }

  .moduleSearch {
    display: flex;
    align-items: center;
  }

  .moduleSpan2 {
    grid-column: span 2;
    @media screen and (max-width: 575px) {
      grid-column: span 1;
    }
  }

  .moduleSpan3 {
    grid-column: span 3;
    @media screen and (max-width: 575px) {
      grid-column: span 1;
    }
  }

  .tableWrapper {
    overflow-x: auto;
  }

  .table {
    display: table;
    border-collapse: collapse;
    width: 100%;
  }

  .tableHeader {
    display: table-row;
    font-weight: bold;
    opacity: 0.5;
    font-size: 80%;
    border-bottom: 1px solid var(--ion-border-color);
  }

  .tableRow {
    display: table-row;

  }

  .tableCell {
    display: table-cell;
    padding: 0.5rem;
    vertical-align: middle;
  }

  .tableCellCenter {
    display: table-cell;
    padding: 0.5rem;
    vertical-align: middle;
    text-align: center;
  }

  .list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 1em;

    li {
      margin: 0;
      padding: 0.5em 1em;
      background: var(--ion-background-color);
      font-weight: bold;
      border-radius: 10px;

      .listItemHeader {
        font-size: 80%;
        opacity: 0.5;
      }

      .flex-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0.5em;
        text-align: center;
        margin: 0.5em 0;
        &.space-evenly {
          justify-content: space-evenly;
        }
      }
      p {
        margin: 0;
      }
    }
  }

  .ClaimProfiles {
    .filter{
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      gap: 26px;
      .toggle-approved {
        gap: 0px;
      }
      .ClearIcon {
        margin-left: -60px;
        cursor: pointer;
        z-index: 100;
      }
    }
    .Claim {
      .first-column {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        svg {
          cursor: pointer;
        }
        svg.collapse {
          transform: rotate(-90deg);
        }
        svg.tab {
          width: 12px;
        }
      }
    }
    .explanation {
      overflow: auto;
    }
  }
  .MyStats {
    overflow: hidden;
    .list {
      gap: 3px;
      .value {
        font-weight: normal;
      }
    }
    @media screen and (max-width: 575px) {
      min-height: 460px;
    }
  }

  .profileImage {
    width: 3em;
    height: 3em;
    object-fit: cover;
    border-radius: 50%;
  }

  .trophyModule {
    display: flex;
    flex-wrap: wrap;
    gap: 2em;
  }

  .trophyWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.5em;
  }

  .trophyIcon {
    width: 3em;
    height: 3em;
  }

  .trophyName {
    font-size: 80%;
    font-weight: bold;
    opacity: 0.5;
    display: block;
    p {
      text-align: center;
      margin: 0;
    }
  }

  
}

.MyMatches {
  .TableFormInput {
    border: none;
    td {
      .bye, .outcome {
        display: flex;
        width: 100%;
        justify-content: center;
        font-weight: bold;
      }
    }
  }
}