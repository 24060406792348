.LoginForm {
  .errorMessage {
    position: absolute;
    top: calc(100% + 0.5rem);
    left: 0;
  }
  .form, .user-info {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-items: flex-start;
    gap: 20px;
  }
  .fieldWrapper {
    display: flex;
    align-items: center;
  }

  .inputWrapper {
    width: 100%;
    position: relative;
  }

  .buttonWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    .message {
      font-size: 0.8em;
    }
    .forget-password {
      cursor: pointer;
    }
  }
}