.Promos {
    tr {
        td {
            .DateFormInput1 {
                width: 240px;
                .datetime {
                    flex-wrap: nowrap;
                    .date {
                        width: 55%;
                    }
                    .time{
                        width: 45%;
                    }
                }
            }
            .DiscountRules {
                display: flex;
                align-items: center;
                flex-direction: column;
                .DiscountRule {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    .buttons {
                        display: flex;
                        flex-direction: row;
                    }
                    .SelectValue, .MultiSelectValue {
                        width: 100%;
                        display: flex;
                        flex-direction: column 
                    }
                    .RangeDateValue {
                        .dates {
                           
                        }
                    }
                    .RangeValue {
                        .range {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                        }
                    }
                    .LogicOption {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        align-items: center;
                        svg.delete {
                            cursor: pointer;
                            color:  red;
                        }
                    }
                }
            }
        }
    }
}