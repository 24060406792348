.Registrations {
    button {
        max-width: 220px;
    }
    .Link-sendmessage {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        cursor: pointer;    
        margin-bottom: 16px;
    }
    .CollectionSummary {
        display: flex;
        flex-direction: column;
        padding: 10px;
        .button {
            width: 310px;
            span {
                margin-left: 6px;
                margin-right: 6px;
            }
        }
        .info {
            display: flex;
            flex-direction: row;
            gap: 20px;
            .total, .collected, .balance {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                span {
                    display: flex;
                    flex-wrap: nowrap;
                }
            }
            .balance {
                span {
                    color: red;
                }
            }
            .balance.credit {
                span {
                    color: orange;
                }
            }
            .balance.zero {
                span {
                    color: green;
                }
            }
            .collected, .transaction-credit {
                span {
                    color: blue;
                }
            }
            .transaction-credit {
                span {
                    font-weight: bolder;
                }
            }
            .transaction-credit.zero {
                span {
                    color: red;
                }
            }
            .pending {
                span {
                    color: red;
                }
            }
        }
    }
    .TableFormInput {
        .toolbar {
            .selected-count {
                font-size: small;
                color: blue;
            }
        }
        .registered-online, .t-shirt {
            text-align: center;
        }
        .t-shirt {
            cursor: pointer;
        }
        tr.add-registration {
            background-color: rgba(0, 255, 255, 0.24);
        }
        tr.combo-highlite {
            background-color: aquamarine;
        }
        tr {
            td {
                .bracket-links {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 6px;
                }
                .email, .gym-name {
                    word-break: break-all; 
                }
                .membership, .team, .gym {
                    min-width: 160px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    width: 100%;
                    .name-link, .team-link, .gym-link {
                        text-align: left;
                    }
                }
                .membership {
                    .Search {
                        height: 100px;
                        min-width: 400px;
                    }
                }
                .MuiFormControl-root.seed, .MuiFormControl-root.weight {
                    min-width: 80px;
                }
                .MuiFormControl-root.paid {
                    min-width: 100px;
                    max-width: 100px;
                    input {
                        text-align: center;
                    }
                }
                .MuiFormControl-root.date-time {
                    min-width: 240px;
                }
                .MultiSelectFormInput.division{
                    width: 360px;

                }
                .SelectFormInput.payment_method {
                    width: 180px;
                }
                .SelectFormInput.status {
                    width: 120px;
                }
                .SelectFormInput.t_shirt{
                    width: 120px;
                }
                .divisionEdit {
                    width: 300px;
                    ul {
                        margin-left: 16px;
                    }
                    button {
                        // margin-top: 6px;
                    }
                    .combos {
                        padding: 0px;
                        margin: 0px;
                        .label {
                            color: blue;
                            font-size: x-small;
                            font-style: italic;
                            text-decoration: underline;
                            opacity: .70;
                        }
                        ul {
                            margin-top: 0px;
                        }
                    }
                }
                .DateFormInput1 {
                    width: 240px;
                    .datetime {
                        flex-wrap: nowrap;
                        .date {
                            width: 55%;
                        }
                        .time{
                            width: 45%;
                        }
                    }
                }
                .change {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                }
            }
            td.index {
                span {
                    display: flex;
                    align-items: center;
                    .RemoveIcon {
                        color: red;
                        cursor: pointer;
                    }
                }
            }
            td.registered-online {
                .PaymentReceivedIcon {
                    color: green;
                }
            }
            td.status {
                max-width: 140px;
            }
            td.division {
                min-width: 400px;
                ul {
                    padding-left: 0px;
                }
                .divisionEdit {
                    width: 100%;
                }
            }
            .Refund {
                display: flex;
                flex-direction: column;
                gap: 10px;
                textarea {
                    height: 140px !important;
                }
                .button {
                    width: 100px;
                }
                .button.refunded {
                    color: chocolate;
                }
                .refund-form {
                    .SelectFormInput {
                        margin-top: 10px;
                    }
                }
            }
        }
    }

    @media (max-width: 575px) {
        padding: 0px;
        .CollectionSummary {
            padding: 0px;
            margin-bottom: 10px;
            .button {
                width: 80%;
                span {
                    margin-left: 0px;
                    margin-right: 0px;
                    white-space: nowrap;       
                    overflow: hidden;          
                    text-overflow: ellipsis; 
                }
            }
        }
    }
}

.SendMessage {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 1px solid rgba(128, 128, 128, 0.603);
    border-radius: 4px;
    padding: 8px;
    .TextAreaFormInput {
        textarea {
            min-height: 200px;
        }
    }
    b {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 18px;
        span {
            display: flex;
            align-items: center;
            flex-direction: row;
        }
    }
    .send-text {
        display: flex;
        flex-direction: row;
        align-items: center;
        button {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 4px;
            .SendMessageIcon {
                transform: rotate(-36deg);
                margin-top: -10px;
            }
        }
    }
}