.Schedules {
    display: grid;
    grid-template-columns: 1fr;
    .title {
        display: flex;
        width: 100%;
        position: relative;
        align-items: center;
        padding-right: 20px;
        h3 {
            overflow-wrap: break-word;
        }
        svg {
            cursor: pointer;
        }
        button {
            width: 20px;
            height: 20px;
            margin-left: 10px;
        }
    }
    .fullscreen {
        margin-bottom: 20px;
        .results {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            margin: 20px;
            .result {
                display: flex;
                position: relative;
                flex-direction: column;
                width: 400px;
                height: 260px;
                align-items: center;
                padding: 20px;
                background-color: white;
                border-radius: 8px;
                .scheduleMat {
                    display: flex;
                    width: 100%;
                    justify-content: flex-start;
                    margin-bottom: 10px;
                    svg {
                        margin-left: auto;
                    }
                }
            
                .resultHeader {
                    width: 100%;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-auto-rows: 1fr;
                    align-items: start;
                    b {
                        text-align: center;
                    }
                }
                
                .time, .winner {
                    position: absolute;
                    bottom: 4px;
                }
                .winner {
                    bottom: 40px;
                }
            }
        }
    }
    margin-bottom: 60px;
    @media (max-width: 575px) {
        display: flex;
        flex-direction: column;
        margin-right: 10px;
        margin-left: 10px;
        width: 96%;
        .FullScreenIcon {
            right: 12px;
        }
        .fullscreen {
            .results { 
                width: 98%;
                margin: 0px;
                .title {
                    width: 96%;
                }
                .result {
                    width: 100%;
                    padding: 0px;
                }
            }
            .DivisionFilter {
                width: 100%;
                margin-left: 4px;
                margin-right: 4px;
                .SelectFormInput-wrapper {
                    width: 98%;
                }
            }
        }
    }
}