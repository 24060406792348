.LeagueMemberships {
    .filter {
        display: flex;
        align-items: center;
        gap: 10px;
        .TextFormInput.filter{
            .MuiFormControl-root {
                max-width: 400px;
                min-width: 400px;
            }
        }
    }
    .memberships {
        .membership {
            margin-bottom: 20px;
        }
    }
    @media (max-width: 575px) {
        margin-left: 8px;
        margin-right: 8px;
        .filter {
            flex-direction: column;
            align-items: unset;
            .TextFormInput.filter{
                display: flex;
                .MuiFormControl-root {
                    width: 100%;
                    max-width: unset;
                    min-width: unset;
                }
            }
        }
    }
}