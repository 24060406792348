.TournamentSchedules {
  .schedule-Completed {
    background-color: rgba(92, 92, 250, 0.363);
  }
  .schedule-In-Progress {
    background-color: rgba(0, 139, 0, 0.363);
  }
  .schedule-Cancel {
    background-color: rgba(148, 149, 151, 0.363);
  }
  .schedule-Delay {
    background-color: rgba(239, 250, 92, 0.363);
  }

  .search-text {
    display: none;
  }
  .competitors {
    display: flex;
    flex-direction: column;
    b {
      text-align: center;
      height: 20px;
      
    }
  }

  .SetOutcome {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .winner {
      display: flex;
      flex-wrap: nowrap;
      .place {
        color: blue;
        font-style: italic;
        margin-left: 10px;
      }
    }
  }

  .SetDuration {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 130px;
  }

  .Placements {
    color: blue;
  }

  .SetScores {
    display: flex;
    flex-direction: column;
    .Scores {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      width: 280px;
    }
  }
}