.TournamentPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .SendMessage {
        width: 70%;
        margin: auto;
    }
    .flag {
        width: 36px;
        height: 36px;
    }
    .timezone {
        font-style: italic;
        font-size: x-small;
        margin-left: 4px;
    }
    .page-title {
        .location-dates {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 6px;
            .dates {
                margin-left: 10px;
            }
            .location {
                display: flex;
                align-items: center;
                gap: 6px;
                ion-icon {
                    border-radius: 8px;
                }
            }
        }
    }
    .registration-title {
        h2 {
            .MuiBadge-root {
                margin-left: 20px;
            }
        }
    }
    .registered-message {
        display: flex;
        justify-content: center;
        color: green;
    }
    .InfoSection {
        .rules {
            .rule-link {
                margin-left: 10px;
            }
        }
        .closed-date {
            color: red;
        }
    }
    .ShowRule {
        margin-top: 20px;
        position: relative;
        button {
            position: absolute;
            z-index: 10;
            left: 4px;
            top: 2px;
        }
        .pdf {
            max-height: 400px;
            overflow: auto;
        }
    }
    .SocialMedias{
        .Link {
            width: 36px;
            height: 36px;
            svg {
                width: 100%;
                height: 100%;
            }
        }
    }
}

.DivisionFilter {
    display: flex;
    flex-direction: column;
    .filter-chips {
        display: flex;
        flex-wrap: wrap;
        gap: 4px;
    }
    .registered-message {
        font-size: smaller;
    }
}