.LeagueInformationForm {
    position: relative;
    button {
        max-width: 180px;
    }
    .icon_button{
        width: 40px;
        height: 40px;
    }
    .TransactionCredits {
        width: 100%;
        padding-bottom: 0px;
        .summary {
            display: flex;
            flex-direction: column;
            gap: 10px;
            .transaction-credit {
                border: 1px solid grey;
                border-radius: 4px;
                padding: 6px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                .row {
                    display: flex;
                    align-items:center;
                    gap: 20px;
                    label {
                        font-weight: bold;
                    }
                    .UpdateCredits {
                        display: flex;
                        flex-direction: column;
                        .fields {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            align-items: center;
                            gap: 10px;
                            .TextFormInput.transaction_credit {
                                width: 100px;
                            }
                            .Status {
                                display: flex;
                                flex-direction: column;
                                span {
                                    margin-left: 10px;
                                }
                                .history {
                                    display: flex;
                                    flex-direction: column;
                                    b {
                                        margin-left: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
                .history {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    cursor: pointer;
                    font-size: small;
                    font-style: italic;
                }
            }
            button {
                width: 80px;
            }
            .BuyTransactionCredits {
                display: flex;
                flex-direction: column;
                gap: 10px;
                margin-top: 20px;
                .SelectFormInput.buy_credit {
                    width: 260px;
                }
                .calculate {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 10px;
                    .total_amount {
                        font-weight: bold;
                    }
                }
            }
        }
    }
    form {
        .hr {
            border-top: 1px gray solid;
        }
        .general {
            .SelectFormInput.status{
                width: 160px;
            }
            .TextFormInput.name, .TextFormInput.website {
                margin-bottom: 14px;
                .MuiFormControl-root {
                    min-width: 400px;
                }
            }
            .TextFormInput.weight_accuracy{
                min-width: 160px;
                max-width: 160px;
            }
        }
        .season {
            display: flex; 
            flex-wrap: nowrap;
        }
        .expiration-notification{
            display: flex;
            align-items: center;
            .TextFormInput {
                width: 80px;
                div {
                    max-width: 80px;
                    margin: 0px;
                }
                label {
                    margin-top: -8px;
                }
                svg {
                    margin-top: -20px;
                }
            }
        }

        .MembershipTypes {
            .membership-type{
                display: grid;
                .text-inputs {
                    display: flex;
                    .TextFormInput{
                        .MuiFormControl-root {
                            min-width: 80px;
                        }
                    }
                }
            }
        }
        .SelectFormInput.period_type {
            width: 200px;
        }
        .WinBys {
            .customs {
                display: flex;
                flex-direction: row;
                gap: 20px;
                .custom {
                    display: flex;
                    flex-direction: column;
                    .text-fields {
                        display: flex;
                        flex-direction: row;
                        .TextFormInput-wrapper {
                            width: 160px;
                            .TextFormInput.code {
                                input {
                                    text-transform: uppercase;
                                }
                            }
                        }
                    }
                }
            }
        }

        .RoundRobinPoints {
            .entry {
                .TextFormInput-wrapper.points {
                    width: 120px;
                }
            }
        }

        .season-duration {
            display: flex;
            flex-direction: row;
            gap: 20px;
            .start, .end {
                display: flex;
                flex-direction: column;
                gap: 16px;
            }
        }
    }

    @media (max-width: 575px) {
        margin-right: 8px;
        margin-left: 8px;
        form {
            .WinBys {
                .customs {
                    flex-direction: column;
                    gap: 0px;
                }
            }
            .TextFormInput{
                margin-right: 4px;
                max-width: 93vw;
                overflow: hidden;
                div {
                    max-width: 93vw;
                }
            }
            .MultiSelectFormInput-wrapper {
                width: 88%;
            }
            .SelectFormInput.currency, 
            .SelectFormInput.country, 
            .SelectFormInput.state,
            .SelectFormInput.win_by{
                width: 88%;
            }
            .season-duration {
                flex-direction: column;
            }
        }
    }
}
