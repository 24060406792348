.ProfileButtons {
    color: white;
    .profile-buttons {
        display: flex;
        flex-direction: row-reverse; /* This reverses the direction from right to left */
        justify-content: flex-start; /* Align items to the start (right end in this case) */
        .notifications {
            position: relative;
            svg {
                cursor: pointer;
                height: 36px;
                width: 36px;
            }
            span {
                cursor: pointer;
                font-weight: bold;
                font-size: small;
                text-align: center;
                position: absolute;
                color: white;
                border: 1px solid blue;
                background-color: blue;
                border-radius: 50%;
                height: 18px;
                width: 18px;
                right: 4px;
                top: -4px;
            }
        }
    }
    .left {
        .buttons {
            margin-right: 20px;
            .settings {
                display: flex;
                align-items: flex-start;
                cursor: pointer;
            }
        }
    }
}

.Settings {
    padding: 40px;
    max-width: 400px;
    .MuiFormControl-root {
        width: 100%;
    }
}


