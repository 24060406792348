.LeaguePage {
  .page-title {
    h1 {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      padding: 6px;
    }
  }

  .section {
    height: 100vh;
    width: 100%;
    background: rgba(255,255,255,0.1);
    border-radius: 1rem;
    padding: 2rem;
    margin-top: 1rem
  }

  .content {
    scroll-behavior: smooth;
  }

  .infoSection {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1em;
    padding: 1em;
    margin: 3em auto;
    max-width: var(--page-max-width);
    background: var(--module-background-color);
    border-radius: var(--border-radius);
    box-sizing: border-box;
    @media (max-width: 575px) {
      grid-template-columns: 1fr;
    }
    .icon {
      filter: invert(1);
    }
  }

  @media screen and (max-width: 575px) {
    margin-left: 8px;
    margin-right: 8px;
  }
}