.Points {
    .team {
        display: flex;
        align-items: center;
        .name {
            margin-left: 10px;
        }
    }
    margin-bottom: 50px;
    @media (max-width: 575px) {
        padding: 0px;
        margin-right: 0px;
        margin-left: 0px;
        width: 100%;
    }
}