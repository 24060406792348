.date {
  opacity: 0.5;
}
.league {
  font-size: small;
  font-style: italic;
  margin-left: 20px;
}

.downloaded, .run {
  color: rgb(0, 217, 255);
  cursor: pointer;
}

.downloadDate {
  font-style: italic;
  font-size: small;
}

.localServerButtons {
  display: flex;
  align-items: center;
}

.TournamentsPage {
  overflow: auto;
}

.timezone {
  font-style: italic;
  font-size: x-small;
  margin-left: 4px;
}