.UsersPage {
    .pageHeaderTitleWrapper {
        .filters {
            .filter {
               padding-bottom: 10px;      
            }
        }
    }
    .users {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        row-gap: 20px;
    }
    .filters {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: 4px;
        .filter {
            width: 200px;
            .FilterInput {
                position: relative;
                .TextFormInput.name_filter {
                    margin-top: 2px;
                    svg {
                        top: 0px;
                    }
                    label {
                        margin-top: -10px;
                    }
                    .MuiFormControl-root, .MuiInputBase-root  {
                        margin-top: 0px;     
                    }
                    .helper {
                        position: absolute;
                        bottom: 0px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 575px) {
        .filters {
            width: 92%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            .filter {
                width: 170px;
                .FilterInput, .SelectFormInput, .MuiFormControl-root {
                    width: 100%;
                }
            }
        }
        .entity-list-body {
            padding: 4px;
            .users {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 6px;
            }
        }
    }
}