@font-face {
    font-family: LCD2U;
    src: url("../../fonts/LCD2U.woff");
}

.LocalServer {
    position: relative;
    .header {
        .tournament-buttons {
            button {
                cursor: pointer;
                margin-right: 10px;
            }
        }
    }
    .CollapseIcon {
        cursor: pointer;
    }
    .CollapseIcon.expand {
        transform: rotate(90deg);
    }
    .module-wrapper {
        display: none;
    }
    .module-wrapper.expand {
        display: block;
    }
    .title {
        display: flex;
        align-items: baseline;
        h1 {
            text-decoration: underline;
        }
    }
    .station {
        width: 620px;
        height: 348px;
    }

    .last_update {
        display: flex;
        font-size: x-small;
        font-style: italic;
    }
    .AssignSchedule {
        width: 100%;
    }

    .schedule_header {
        font-weight: bold !important;
        color: blue !important;
        background-color: rgb(199, 199, 199) !important;
    }

    .schedule_item {
        cursor: pointer;
    }

    .schedule_item_competitors{
        display: flex;
        justify-content: space-between;
        b {
            font-style: italic;
            font-size: small;
        }
    }

    .schedule_item:hover {
        color: blue;
    }

    .station_name {
        color: green;
        margin-right: 10px;
        margin-left: 10px;
    }

    .local_server_wrapper {
        margin-top: 80px;
        margin-left: 30px;
        margin-right: 30px;
    }

    .scrollable_element{
    width: 2px;
    }

    .videoHolderWrapper {
        width: 200px;
        height: 100px;
        border: 1px solid black;
        margin: auto;
        background-color: black;
    }

    .LocalWeightIn, .LocalSchedule, .LocalCamera {
        margin: 20px;
    }

    .MuiBadge-root{
        color: orange;
        font-weight: bold;
    }

    .CameraData {
        .videoHolderWrapper {
            position: relative;
            display: flex;
            video {
                width: 100%;
                height: 100%;
            }
            button, .RTCStatusIcon {
                position: absolute;
                top: -30px;
            }
            button.yellow {
                background-color: yellow;
            }
            button.red {
                background-color: red;
            }
            button.green {
                background-color: green;
            }
            button.orange {
                background-color: orange;
            }
            .RTCStatusIcon {
                right: 0px;
            }
        }
    }

}

.Notifications, .NotificationsLocalServer {
    margin: 4px;
    position: fixed;
    background-color: rgba(255, 255, 0, 0.05);
    width: 300px;
    z-index: 100;
    right: 42px;
    border-radius: 8px;
    border: 1px solid gray;
    overflow: hidden;
    .notifications {
        min-width: 300px;
        overflow: auto;
        overflow-x: hidden;
        .send {
            width: 100%;
            position: relative;
        }
    }

    .notification {
        display: 'block';
        white-space: 'nowrap';
    }

    .notificationTimeStamp {
        font-size: x-small;
        color: darkblue;
        margin-right: 2;
        font-style: italic;
        text-decoration: underline; 
        text-align: start;
    }

    .notificationMessageWrapper {
        display: flex;
        margin-left: 10px;
        .by{
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            .who {
                font-weight: bold;
                margin-right: 6px;
                color: black;
            }
            .author {
                font-size: x-small;
                margin-right: 6px;
                color: black;
            }
        }
    }

    .notificationMessage {
        white-space: unset;
        margin-left: 4px;
        margin-right: 4px;
        font-style: italic;
        color: rgb(250, 165, 6);
        font-size: smaller;
        align-items: baseline;
        display: contents;
    }

    .ClearNotificationIcon, .SendOutlinedIcon {
        position: absolute;
        z-index: 10;
        right: 6px;
        top: -1px;
        cursor: pointer;
    }
    .ClearNotificationIcon {
        color: red;
    }
    .SendOutlinedIcon {
        transform: rotate(-35deg);
        color: black;
        top: 12px;
    }
}
.NotificationsLocalServer {
    position: unset;
}

.LocalCamera {
    position: relative;
    margin: 20px;
    h1 {
        margin-bottom: 20px;
    }
    .camera {
        display: grid;
        grid-gap: 20px;
        .SelectFormInput-wrapper {
            width: 100%;
            .SelectFormInput {
                min-width: 400px;
            }
            display: flex;
        }
        .buttons {
            .hide {
                display: none;
            }
            .rec {
                svg {
                    color: red;
                }
            }
            .pause {
                svg {
                    color: yellow;
                }
            }
            .save {
                .blink {
                    color: orange;
                }
            }
            .saving, .recording {
                font-size: x-small;
                font-weight: normal;
                font-style: italic;
                color: blue;
            }
        }
    }
    .video {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        position: relative;
        .hide {
            visibility: hidden;
        }
        .stream_wrapper{
            position: relative;
            .canvasRef {
                position: absolute;
                visibility: hidden;
            }
            .cameraStreamRef {
                position: absolute;
                .camera-wrapper {
                    position: relative;
                    height: 1vh;
                    video {
                        width: 100%;
                    }
                    .RTCStatusIcon {
                        position: absolute;
                        right: 24px;
                    }
                    .RTCStatusIcon.yellow {
                        color: yellow;
                    }
                    .RTCStatusIcon.red {
                        color: red;
                    }
                    .RTCStatusIcon.green {
                        color: green;
                    }
                    .RTCStatusIcon.orange {
                        color: orange;
                    }

                    .fullscreen {
                        position: absolute;
                        z-index: 101;
                        right: 2px;
                        color: blue;
                        cursor: pointer;
                    }
                    .OverLayScoreCard {
                        position: absolute;
                        background-color: black;
                        top: 10px;
                        display: flex;
                        align-items: center;
                        z-index: 100;
                        .clock {
                            margin-left: 10px;
                            margin-right: 10px;
                            font-family: "LCD2U", monospace;
                            font-size: 2em;
                            color: white;
                            background-color: black;
                        }
                        .Reg {
                            padding-left: 10px;
                            display: grid;
                            grid-template-columns: 0fr 1fr 1fr;
                            align-items: center;
                            .Scores {
                                display: grid;
                                grid-template-columns: .1fr .1fr .1fr;
                                margin-left: 20px;
                                font-family: "LCD2U", monospace;
                                span {
                                    margin-right: 4px;
                                    text-align: right;
                                    width: 20px;
                                }
                                .point2 {
                                    color: orange;
                                }
                                .neg {
                                    color: red;
                                }
                            }
                            .reg {
                                white-space: nowrap;
                            }
                            .flag {
                                min-width: 16px;
                                height: 12px;
                            }
                        }
                        .Reg.reg1 {
                            background-color: white;
                            .point {
                                color: blue;
                            }
                        }
                        .Reg.reg2 {
                            background-color: blue;
                            color: white;
                            .point {
                                color: white;
                            }
                        }
                    } 
                }
            }
        }
    }
    .playback {
        .hide {
            display: none;
        }
        .videoPlaybackRef {
            flex: 1;
            height: 300px;
            margin-top: 20px;
        }
    }
}

.ProfileButtons {
    h1 {
        display: flex;
        align-items: center;
        .online-indicator.online {
            color: green;
        }
        .online-indicator{
            margin-left: 20px;
            color: red;
        }
    }
}