.Divisions {
    .RenderComboList {
        .IonSpinner {
            color: white;
        }
    }
    .DivisionTable {
        
    }
    .Division {
        form {
            .MultiSelectFormInput.rank {
                margin-bottom: 16px;
            }
            .SelectFormInput {
                margin-bottom: 14px;
            }
            .TextFormInput-wrapper.name{
                width: 100%;
                div {
                    width: inherit;
                }
            }
            .TextFormInput-wrapper.code{
                width: 100%;
                div {
                    width: inherit;
                }
            }
            .SelectFormInput.status{
                width: 120px;
                margin-top: 16px;
            }
            .code-wrapper{
                display: flex;
                align-items: center;
                margin-bottom: 10px;
            }
            .requirements-wrapper {
                .SelectFormInput {
                    margin-bottom: 14px;
                    width: 260px;
                }
                .SelectFormInput.rank {
                    width: 460px;
                }
            }
            .default-timer-wrapper {
                display: flex;
                flex-direction: row;
                .TextFormInput{
                    max-width: 100%;
                }
            }
        }
    }

    .Generate {
        padding: 20px;
        .RenderChipList {
            .group-name {
                margin-bottom: 10px;
                font-style: italic;
                font-size: small;
                text-decoration: underline;
                color: aqua;
            }
        }
        .RenderComboList {
            .Render {
                .List {
                    list-style-type: none;
                }
                .select-all {
                    font-size: small;
                    font-style: italic;
                    text-decoration: underline;
                    color: aqua;
                }
            }
        }
        .hashtags {
            margin-top: 10px;
            width: 90%;
        }
        .chips-wrapper {
            display: flex;
            flex-direction: column;
            .chips {
                display: table-row;
            }
            .filter-count {
                margin-top: 20px;
                font-size: smaller;
                font-style: italic;
                label {
                    text-decoration: underline;
                }
                .count {
                    color: aqua;
                }
            }
        }
        .filter-text {
            width: 300px;
            margin: 14px;
        }
        .buttons {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
    @media (max-width: 575px) {
        margin-left: 8px;
        margin-right: 8px;
        .Division {
            form {
                .TextFormInput {
                    overflow: hidden;
                    color: red;
                    div {
                        max-width: 94vw;
                    }
                }
                .SelectFormInput{
                    width: 94vw !important;
                }
                .code-wrapper{
                    flex-direction: column;
                    align-items: flex-start;
                }
            }
        }
    }
}