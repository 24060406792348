:root {
    --mobile-size: 575px;

    /* Ionic overrides */
    --ion-font-family: 'Inter', sans-serif;

    --ion-background-color: #F7F6F0;
    --ion-text-color: black;

    --ion-color-primary: #c5ef4b;
    --ion-color-primary-contrast: #272A2F;

    /*navigation color*/
    --ion-color-tertiary: #16171a;

    --ion-color-warning: #fbff09;
    --ion-color-warning-contrast: #000000;

    --module-background-color: white;

    --ion-toolbar-background: var(--ion-color-tertiary);

    --ion-border-color: #e6e6e6;

    --page-max-width: 1200px;

    --border-radius: 25px;
}
