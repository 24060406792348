.entity-edit {
    margin: 2px;
    .button {
        margin: 4px;
    }
    span {
        margin-right: 2px;
    }
}

.menu {
    --side-max-width: 250px;
}

.mainContent {
    width: 100%;
    height: 100%;
}

.tabs {
    @media screen and (max-width: 575px) {
        display: flex;
    }
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: none;
    justify-content: space-evenly;
    align-items: center;
    background: var(--ion-background-color);
    border-top: 1px solid var(--ion-border-color);
    padding: .2rem 0 .2rem 0;
}

.selected {
    color: rgb(114, 114, 255);
}

.tab {
    background: none;
    color: var(--ion-text-color);
}

.tabIcon {
    display: block;
    margin: 0 auto;
    font-size: 130%;
}

.tabIconMenu {
    margin-right: 0px !important;
    //margin-bottom: 0.7em !important;
}

.tabLabel {
    font-size: 70%;
}

.menuLogo {
    width: 6rem;
    height: 6rem;
    margin-left: 10px;
}

.navItem {
    font-weight: bold;

    :global {
        .sc-ion-label-md-h {
            //--color: var(--ion-color-primary);
        }
    }
}

.navItemLabel {
    //font-family: 'Evogria', sans-serif !important;
}

.navList {
    padding-top: 0px;
    padding-bottom: 0px;
    i {
        min-width: 1em;
        margin-right: 1em;
    }
}

.navIcon {
    margin-right: 1rem;
    color: inherit;
}

.global-message {
    opacity: 50%;
    z-index: 1000;

}

.topNavBar {
    display: flex;
    flex-direction: row;
    color: white;
    border-bottom: 1px solid var(--ion-color-step-150);
    .icon {
        width: 1.5em;
        height: 1.5em;
    }
    .GIHBanner {
        padding: 2px;
        padding-bottom: 0px;
        display: flex;
        justify-content: center;
        .Logo{
            padding: 1px;
            width: 149px;
            height: 56px;
        }
    }
    @media screen and (max-width: 575px) {
        padding-top: .05em;
        .GIHBanner {
            top: 0px;
            position: absolute;
            left: 30%;
            transform: translateX(-50%);
            .Logo {
                height: 36px;
                width: 96px;
            }
        }
    }
}

.menuProfile {
    display: flex;
    justify-content: space-between;
    ion-buttons {
        width: 50%;
    }
    .login-button {
        cursor: pointer;
        display: flex;
        align-items: center;
        span {
            font-size: smaller;
        }
    }
}

.AppMessage{
    position: fixed; 
    left: 40px;
    width: 80%; 
    max-height: 150px;
    overflow: hidden; 
    z-index: 1000;
    opacity: 0.95;
}

.ProfileButtons {
    position: relative;
    .buttons-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-end;
        margin-right: 10px;
        .HelpIcon {
            align-items: right;
            color: rgb(0, 238, 255);
            cursor: pointer;
        }
        .session-buttons {
            display: flex;
            flex-direction: row;
            gap: 0px;
            .session-buttons-icon {
                width: 20px;
                height: 20px;
                margin-right: 4px;
                span {
                    top: 5px;
                    left: -1px;
                    width: 18px;
                }
            }
            .message {
                .MessageIcon.sse-connect {
                    color: rgb(10, 228, 10);
                }
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .ProfileButtons {
        position: absolute;
        right: 0px;
        bottom: 0px;
    }
    .GIHBanner{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 10px;
    }
}

.send-a-message {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.Family {
    a.family-link {
        display: flex;
        align-items: center;
    }
}

.incomplete-email-validation {
    a {
        color: rgb(255, 255, 171);
    }
}

div[role=dialog] {
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    z-index: 100;
}

.super-info {
    font-style: italic;
    font-size: xx-small;
    font-weight: normal;
}

.warning .Alert {
    background-color: yellow !important;
    color: black !important;
    border: 1px solid black;
}

.image-icon {
    width: 1.5em;
    height: 1.5em;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover; /* Ensure the image covers the container without distortion */
        border-radius: 50%; /* Make the image circular */
    }
}

span.link {
    cursor: pointer;
    color: var(--ion-color-primary);
}

.spinner {
    color: blue;
    animation: spin 1s linear infinite; /* Rotation animation */
}

.TextFormInput {
    div {
      margin-bottom: 0px;
      margin-top: 10px;
    }
    label {
        top: 10px;
    }
}

.SelectFormInput {
    div {
        margin-bottom: 0px;
        margin-top: 2px;
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.mainContent {
    .content {
        display: flex;
        flex-direction: row;
        gap: 0px;
        height: 100%;
        width: 100%; 
    }
}

.blank-option{
    width: 100px;
}

.all-option {
    color: blue;
    text-align: center;
    display: block;
    width: 100%;
    white-space: nowrap;
    padding: 6px;
}

.entity-list-body {
    max-width: 640px !important;
    .entity-item-name {
        white-space: normal; /* Allows the text to wrap onto the next line */
        word-wrap: break-word; /* Forces long words to break onto the next line */
        overflow-wrap: break-word; /* Ensures words will break in all modern browsers */
    }
}

ion-app {
    position: relative;
    .global-dialog {
        box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
        padding: 20px;
        border-radius: 8px;
        background-color: lightgoldenrodyellow;
        color: black;
        border: 1px grey solid;
        position: absolute;
        min-width: 300px;
        min-height: 200px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); /* Move back by half its dimensions */
    }
}