.GymInformationForm{
    button {
        max-width: 180px;
    }
    .icon_button{
        width: 40px;
        height: 40px;
    }
    form {
        .SelectFormInput.status{
            width: 160px;
        }
        .TextFormInput{
            margin-bottom: 14px;
        }
    }
    .merge-gym {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .button {
            max-width: 80px;
        }
    }

    @media (max-width: 575px) {
        margin-left: 8px;
        margin-right: 8px;
        form {
            .TextFormInput{
                margin-right: 4px;
                max-width: 90vw;
                overflow: hidden;
                div {
                    max-width: 90vw;
                }
            }
            .MultiSelectFormInput-wrapper {
                width: 92%;
            }
            .SelectFormInput{
                width: 92%;
            }
        }
    }
}
