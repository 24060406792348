
.edit {
  width: 320px;
  max-width: 320px;
}

.competitor {
  position: relative;
  background: var(--module-background-color);
  border: 1px solid var(--ion-border-color);

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.25em;
  min-width: 15em;
  white-space: nowrap;
  padding: 0.5em 1em;
  border-radius: 10px;
  min-height: 80px;
  max-height: 80px;
  :global {
    a {
      color: inherit;
      text-decoration: none;
    }
  }
}

.competitorSelectable {
  color: blue;
}

.thirdFourth {
  padding: 0px;
  position: absolute;
  margin-top: 80px;
  right: 2px;
  z-index: 10;
}
.placement {
  position: absolute;
  right: 6px;
  margin-top: -26px;
  color: blue;
}

//.competitor:nth-child(odd):after {
//  content: "";
//  height: calc(50% + 0.5em);
//  width: 0.5em;
//  position: absolute;
//  top: 50%;
//  left: 100%;
//  border-top: 1px solid black;
//  border-right: 1px solid black;
//}
//
//.competitor:nth-child(even):after {
//  content: "";
//  height: calc(50% + 0.5em);
//  width: 0.5em;
//  position: absolute;
//  top: -0.5em;
//  left: 100%;
//  border-bottom: 1px solid black;
//  border-right: 1px solid black;
//}
//
//.competitor:before {
//  content: "";
//  height: 50%;
//  width: 0.5em;
//  position: absolute;
//  top: 50%;
//  right: 100%;
//  border-top: 1px solid black;
//}

.competitor.hovered {
  filter: invert(1);
}

.competitorName {
  display: flex;
  font-weight: bold;
  gap: 0.25em;
  font-size: normal;
}

.competitorTeam {
  font-size: 80%;
  opacity: 0.75;
}

.flagIcon {
  width: 1.0em;
}

.editCompetitorButton {
  position: absolute;
  margin: 0.5em !important;
  right: 0;
  top: 0;
  :global {
    .icon {
      margin-right: 0;
      width: 1.5em;
      height: 1.5em;
    }
  }
}

.round_header {
  position: absolute;
  top: -24px;
  z-index: 100;
  color: blue;
}
