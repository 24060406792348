.GymPage {
  .page-title {
    h1 {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      padding: 6px;
    }
  }
  .page-header {
    position: sticky;
  }
  .gymName {
    margin-top: 7.5vw;
  }

  .section {
    // height: 100vh;
    //width: 100%;
    background: var(--ion-toolbar-background);
    border-radius: 1rem;
    padding: 2rem;
    margin-top: 1rem
  }

  .content {
    scroll-behavior: smooth;
  }

  .infoSection {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1em;
    padding: 1em;
    margin: 3em auto;
    max-width: var(--page-max-width);
    background: var(--module-background-color);
    border-radius: var(--border-radius);
    box-sizing: border-box;
    @media (max-width: 575px) {
      grid-template-columns: 1fr;
    }
    .icon {
      filter: invert(1);
    }
    .location {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      .flag {
        margin-left: 10px;
        width: 20px;
        height: 20px;
      }
    }
  }

  .RegisteredIcon.pending {
    color: orange;
  }
  .RegisteredIcon.active {
    color: green;
  }
  .RegisteredIcon {
    border: 1px solid black;
    border-radius: 50%;
    margin-left: 10px;
    margin-right: 10px;
  }

  @media screen and (max-width: 575px) {
    margin-left: 8px;
    margin-right: 8px;
  }
}

.ClaimProfile {
  border: 1px solid rgba(128, 128, 128, 0.2);
  border-radius: 8px;
  padding: 14px;
  .TextAreaFormInput, .MuiFormControl-root{
    width: 400px;
    height: 220px;
    textarea {
      width: 92% !important;
      height: 100% !important;
    }
  }
  .supporting-file{
    height: 60px;
    .external-file {
      display: flex;
      align-items: center;
      span {
        font-size: small;
        font-style: italic;
      }
    }
  }
  .buttons {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 575px) {
    .ClaimProfile {
      width: 100%;
      .TextAreaFormInput, .MuiFormControl-root{
        width: 100%;
      }
    }
  }
}

