.DiscountInfo {
  display: grid;
  div {
    margin:0px;
    margin-top: 0px !important;
    margin-bottom: 0px;
  }
}

.sub_form {
  margin: 20px;
}

.FormActionButtons {
  width: 100%;
  .IonButtons {
    display: flex;
    .save.has-change {
      background-color: yellow;
      border: 1px solid black;
    }
  }
}

.text-input {
  position: relative;
  .MuiInputBase-root{
    width: 100%;
  }
  .enter-icon {
    position: absolute;
    right: 0px;
    opacity: .2;
  }
}

@media (max-width: 575px) {
  .sub_form {
    margin: 2px;
  }
  .FormActionButtons {
    .IonButtons {
      flex-wrap: wrap;
    }
  }
}

.FilterInput {
  max-width: 460px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0px;
  .MuiFormControl-root {
    width: 98%;
  }
  .helper {
    position: absolute;
    right: 12px;
    bottom: 0px;
    opacity: .7;
    font-size: x-small;
    font-style: italic;
    text-decoration: underline;
    color: blue;
  }
  @media (max-width: 575px) {
    width: 92%;
  }
}

.capitalize {
  input {
    text-transform: uppercase;
  }
}

.RemoveSubFormIcon {
  margin-bottom: 20px;
  margin-top: 4px;
  display: flex;
  position: relative;
  align-items: end;
}

.CodeInput {
  color: blue;
  font-style: italic;
}