.Poolings {
    margin-bottom: 60px;
    .buttons {
        display: flex;
        margin-bottom: 20px;
        button {
            max-width: 180px;
        }
    }
    .pools-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 10px;
        .Pool.selected {
            border: 0px solid blue;
        }
        
        .Pool.collapse {
            border: 1px rgba(128, 128, 128, 0.377) solid;
            border-radius: 4px;
            padding: 4px;
        }
        .Pool {
            width: 100%;
            .header {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                gap: 10px;
                .icon_button {
                    width: 16px;
                    height: 16px;
                    img {
                        filter: none;
                    }
                }
                .count {
                    color: blue;
                }
                .pool-name {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    svg {
                        cursor: pointer;
                    }
                }
                .expand {
                    margin-left: auto;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    color: blue;
                    font-weight: bold;
                    font-size: small;
                    cursor: pointer;
                    border-radius: 4px;
                    padding: 2px;
                    svg {
                        width: 20px;
                        height: 20px;
                    }
                }
                .ExpandIcon, .CollapseIcon {
                    border: 0px solid gray;
                    border-radius: 50%;
                }
            }
            .PoolAbbr {
                cursor: pointer;
                display: flex;
                flex-direction: row;
                gap: 10px;
            }
            .ToolBar {
                width: 100%;
                ion-buttons {
                    justify-content: flex-end;
                }
                .SelectPools {
                    margin-top: 2px;
                    border-radius: 4px;
                    padding-left: 10px;
                    padding-right: 10px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-end;
                    border: 0px solid rgba(128, 128, 128, 0.575);
                    .select-pool {
                        display: flex;
                        align-items: center;
                        span {
                            white-space: nowrap;
                            color: blue;
                        }
                    }
                }
            }
            .RangeFilter {
                min-width: 340px;
                margin-right: 20px;
                margin-top: 20px;
                span {
                    font-size: xx-small;
                }
                span.label {
                    font-size: x-small;
                    margin-left: 20px;
                }
            }
            .message {
                font-size: small;
                color: red;
            }
            .EditName {
                padding: 12px;
                div {
                    margin-top: 8px;
                }
            }
        }
    }

    @media (max-width: 575px) {
        .pools-wrapper {
            flex-direction: column;
            .pool-wrapper {
                width: 100%;
            }
        }
    }
}