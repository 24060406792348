.TournamentDivisions {
    position: relative;
    .delete-alert {
        position: absolute;
        top: 50%; 
        left: 50%; 
        transform: translate(-50%, -50%); 
    }
    button.apply-selects {
        margin-top: 4px;
    }
    .division-display {
        display: flex;
        gap: 10px;
    }
    .DivisionForm {
        width: 400px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .TournamentDivisionsTable {
        margin-top: 20px;
        .bracket-type {
            position: relative;
        }
    }
    .ImportDivisions{
        position: relative;
        .buttons {
            padding-top: 10px;
        }
        button.cancel {
            position: absolute;
            right: 4px;
            top: 2px;
        }
        .CustomButtons {
            display: flex;
            flex-direction: row;
            button {
                margin-right: 4px;
            }
        }
        .RangeFilter {
            margin-left: 20px;
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            z-index: 100;
            .MultiSelectFormInput.hashtags {
                width: 300px;
                .MuiTextField-root {
                    margin-left: 0;
                }
            }
            .range {
                display: flex;
                flex-direction: row;
                .RangeSlider {
                    padding-top: 36px;
                    height: 80px; 
                    width: 300px;
                    display: flex;
                    flex-direction: row;
                    margin-right: 20px;
                    .label {
                        white-space: nowrap;
                        margin-right: 18px;
                        text-decoration: underline;
                        font-size: small;
                        font-style: italic;
                    }
                }
            }
            .category {
                display: flex;
                gap: 10px;
                .SelectFormInput {
                    width: 240px;
                }
            }
        }
        .TransferList {
            .CustomList {
                .ListItem {
                    .ListItemText {
                        span {
                            .division-display {
                                display: flex;
                                gap: 10px;
                            }
                        }
                        
                    }
                }
            }
        }
    }

    @media (max-width: 575px) {
        .DivisionForm {
            width: 100%;
        }
        .ImportDivisions{
            margin-bottom: 100px;
            .RangeFilter {
                flex-direction: column;
                margin-top: -20px;
                margin-left: 0;
                width: 100%;
                .range {
                    .RangeSlider {
                        flex-direction: column;
                        margin-bottom: 10px;
                        width: 100%;
                    }
                    width: 260px;
                }
                .category, .range {
                    flex-direction: column;
                }
                .MultiSelectFormInput.hashtags{
                    .MuiFormControl-root {
                        width: 90% !important;
                    }
                }
                .MuiTextField-root {
                    margin-left: 0px;
                }
            }
        }
    }
}