.MembershipInfo {
    .status {
        display: flex;
        flex-direction: row;
        .SelectFormInput.status{
            margin-top: 10px;
            width: 140px;
        }
        .SelectFormInput.membership_type{
            margin-top: 10px;
            width: 200px;
        }
    }
    .dates {
        display: flex;
        .date {
            width: 200px;
        }
    }

    @media (max-width: 575px) {
        .status, .dates {
            flex-direction: column;
        }
        textarea {
            width: 98%;
        }
    }
}