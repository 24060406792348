.AddressForm {
    // border: 1px rgba(128, 128, 128, 0.2) solid; 
    padding: 0px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    .addresses {
        width: 400px;
        display: flex;
    }
    .TextFormInput-wrapper.city, .TextFormInput-wrapper.suburb  {
        width: 100%;
    }
    .TextFormInput-wrapper.address {
        width: 340px;
    }
    .TextFormInput-wrapper.zip{
        width: 180px;
    }

    .SelectFormInput.state, .SelectFormInput.region{
        margin-top: 18px;
        max-width: 280px;
    }

    @media (max-width: 575px) {
        width: 100%;
        .SelectFormInput.country, .SelectFormInput.state {
            max-width: 92%;
        }
        .addresses {
            width: 100vw;
            display: flex;
            flex-direction: column;
        }
        .MuiFormControl-root.TextFormInput-wrapper.city {
            width: 100% !important;
            .TextFormInput{
                .MuiFormControl-root {
                    min-width: 100%;
                }
            }
        }
    }
}