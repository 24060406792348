.SocialMedias {
    display: flex;
    // border: 1px rgba(128, 128, 128, 0.2) solid; 
    padding: 6px;
    border-radius: 6px;
    flex-wrap: wrap;
    .SelectFormInput.name {
        .MuiFormControl-root {
            width: 300px;      
        }
    }
    .TextFormInput.url {
        .MuiFormControl-root {
            min-width: 400px;
        }
    }
    @media (max-width: 575px) {
        flex-direction: column;
        .socialMediaWrapper {
            width: 90%;
        }
        .TextFormInput.url {
            .MuiFormControl-root {
                min-width: 92%;
            }
        }
    }
}
.SocialMedias.empty {
    border: none;
}
