.TournamentEditPage {
    h1#tournament_title{
        position: sticky;
    }
    .tournament-name {
        display: grid;
        .league {
            margin-left: 20px;
        }
    }
    .TournamentPricing {
        .TableFormInput {
            .TableContainer {
                table {
                    margin-bottom: 40px;
                    tr {
                        td {
                            .FeePeriods{
                                .Paper {
                                    border-radius: 4px;
                                    padding: 10px;
                                    .FeePeriod {
                                        display: flex;
                                        flex-direction: column;
                                        padding: 10px;
                                        gap: 10px;
                                        .period-name, .period-dates {
                                            display: flex;
                                            width: 300px;
                                            gap: 10px;
                                        }
                                        .period-dates {
                                            flex-direction: column;
                                        }
                                        .period-name {
                                            flex-direction: row;
                                            flex-wrap: nowrap;
                                            .fee {
                                                width: 100px;
                                            }
                                        }
                                    }
                                }
                            }
                            .DivisionLogics {
                                display: flex;
                                flex-direction: column;
                                gap: 20px;
                                .DivisionLogic {
                                    background-color: beige;
                                    margin: 4px;
                                    .header {
                                        display: flex;
                                        align-items: center;
                                    }
                                    .SelectDivisions {
                                        margin: 4px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        
    }
}