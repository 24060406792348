.TeamPage {
  .page-title {
    h1 {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      padding: 6px;
    }
  }

  .section {
    // height: 100vh;
    //width: 100%;
    background: var(--ion-toolbar-background);
    border-radius: 1rem;
    padding: 2rem;
    margin-top: 1rem
  }

  .infoSection {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1em;
    padding: 1em;
    margin: 3em auto;
    max-width: var(--page-max-width);
    background: var(--module-background-color);
    border-radius: var(--border-radius);
    box-sizing: border-box;
    @media (max-width: 575px) {
      grid-template-columns: 1fr;
    }
    .icon {
      filter: invert(1);
    }
    .location {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      .flag {
        margin-left: 10px;
        width: 20px;
        height: 20px;
      }
    }
  }
  
  .content {
    scroll-behavior: smooth;
  }

}