.UserPage {
  .coverPhoto {
    width: 100%;
    height: 25vh;
    object-fit: cover;
    //border-radius: 10px;
  }

  .details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;
    gap: 10px;
    margin-bottom: 16px;
    .level {
      font-size: smaller;
      font-weight: bold;
    }
  }

  .user-info {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
    .profileImage {
      width: 60px;
      height: 60px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover; /* Ensures the image covers the entire circular area */
        border: 2px solid #fff; /* Optional: Adds a border around the image */
        box-shadow: 0 0 5px rgba(0,0,0,0.3); /* Optional: Adds a subtle shadow */
      }
    }
    .name {
      position: relative;
      bottom: 0px;
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      h1 {
        padding: 4px;
        border-radius: 4px;
        .flag {
          height: 1em;
          width: 1em;
          position: absolute;
        }
      }
    }
    .location {
      max-width: 200px;
      height: 100%;
      font-size: x-small;
    }
  }

  .Stats {
    display: flex;
    align-items: center;
    background: var(--module-background-color);
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 1.2em;
    .private, .data  {
      margin-left: 10px;
    }
    .data {
      display: flex;
      gap: 1em;
      div {
        white-space: nowrap;
        font-weight: bold;
        span {
          font-style: italic;
          font-weight: normal;
          font-size: xx-small;
        }
      }
    }
  }

  .ContactInformation{
    .Paper {
      padding: 20px;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  @media screen and (max-width: 575px) {
    margin-top: -1em;
    margin-bottom: 5em;
    .user-info {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .name {
        .stats {
          div {
            font-size: .7em;
          }
        }
      }
    }
    .Tabs {
      padding: 2px;
      .tabSection {
        min-height: 300px;
        margin-bottom: 20px;
      }
    }
  }

}
