.DateFormInput1 {
    display: inline-block;
    position: relative;
    width: 340px;
    .endDate, .startDate {
        background-color: orange;
    }
    .mark {
        background-color: yellowgreen;
        font-weight: bold;
    }
    .datetime {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .TextField {
            width: 100%;
        }
        .date {
            display: flex;
            align-items: center;
            max-width: 200px;
            svg {
                margin-left: -30px;
                z-index: 10;
                cursor: pointer;
            }
        }
        .time {
            max-width: 120px;
            .pattern {
                font-size: small;
                font-style: italic;
                color: blue;
            }
        }
        .react-datepicker {
            position: relative;
            z-index: 100;
        } 
    }
    
    .error {
        color: red;
        font-size: small;
    }
    .DatePicker-wrapper{
        position: relative;
        .title {
            z-index: 101;
            position: absolute;
            top: 118px;
            left: 38px;
            // transform: translate(-90%, -545%);
            background-color: yellow;
            border: 1px solid gray;
            padding-right: 4px;
            padding-left: 4px;
            border-radius: 4px;
            font-weight: bold;
        }
    }
    .custom-datepicker {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    @media (max-width: 575px) {
        width: 100%;
        .datetime {
            width: 100%;
            .date, .time {
                width: 90%;
            }
            gap: 10px;
        }
    }
}