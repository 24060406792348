.CartsPage {
    padding: 20px;
    h2.cart-header {
        display: flex;
        align-items: center;
        .RemoveIcon {
            color: red;
            z-index: 10;
        }
        .tournament-date {
            font-size: small;
        }
    }
    .CartPage {
        .cart {
            border: 1 solid whitesmoke;
            padding: 20px;
            padding-bottom: 200px;
            position: relative;
            h5, h4 {
                text-decoration: underline;
            }
            h4.Total {
                margin-top: 20px;
                margin-bottom: 20px;
            }
            .entity-link {
                cursor: pointer;
            }
            button.button-cart {
                margin-bottom: 20px;
                margin-top: 20px;
                ion-icon {
                    margin-right: 10px;
                }
            }
            .waiver-wrapper {
                .buttons {
                    button {
                        h5 {
                            display: flex;
                            align-items: center;
                        }
                    }
                }
                .waiver {
                    border-radius: 8px;
                    border: 1px rgba(128, 128, 128, 0.438) solid;
                    padding: 20px;
                    button {
                        h5 {
                            display: flex;
                            align-items: center;
                        }
                    }
                    p {
                        margin-left: 20px;
                        white-space: pre-line;
                    }
                } 
            }
            
            .Promo {
                margin-top: 20px;
                .promo-fields {
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    margin-left: 20px;
                    button {
                        margin-left: 10px;
                    }
                }
            }
            .TShirt {
                margin-top: 20px;
                margin-bottom: 20px;
                .options {
                    margin-left: 20px;
                }
                .expired-date {
                    font-size: small;
                    margin-left: 20px;
                }
            }
            .PaymentOptions {
                padding: 16px;
                .payment {
                   padding: 16px;
                }
            }
            .CartItems-wrapper {
                .index {
                    .DeleteIcon {
                        cursor: pointer;
                    }
                }
                .Description {
                    .combo-name{
                        text-decoration: underline;
                    }
                    ul {
                        margin: 0px;
                    }
                }
            }
            .zero-balace-payment {
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                gap: 20px;
                .button {
                    width: 100px;
                }
            }
            .cart_notes, .waiver {
                margin-left: 20px;
                font-style: italic;
                white-space: pre-line;
            }
        }
    }
    @media screen and (max-width: 575px) {
        .CartPage {
            .cart {
                .waiver {
                    button {
                        width: 90%;
                        h5 {
                            span {
                                white-space: normal;
                            } 
                        }
                    }
                }
            }
        }
    }
}