.TextAreaFormInput {
    position: relative;
    background-color: initial;
    min-height: 80px;
    margin-bottom: 10px;
    margin-top: 10px;
    .FormLabel {
        padding-left: 4px;
        padding-right: 4px;
        font-size: smaller;
        margin-bottom: 0;
    }
    .TextareaAutosize {
        padding: 12px;
        border-radius: 8px;
        color:  inherit;
        background-color: inherit;
    }
    .FormLabel {
        margin-bottom: -10px;
        margin-left: 10px;
        z-index: 100;
        span {
            background-color: var(--ion-background-color);     
            padding-left: 4px;
            padding-right: 4px;
        }
    }
    .FormLabel.focus {
        color: #007aff;
    }
    textarea:focus-visible {
        border: 2px solid #007aff;
        outline: none;
    }
    .TextareaAutosize.error {
        border: 1px red solid;
    }
    .FormHelperText.error, .FormLabel.error {
        color: red;
    }

    @media screen and (max-width: 575px) {
        min-width: fit-content;
    }
}