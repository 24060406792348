.TournamentCartReport {
    .Summary {
        max-width: 300px;
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Two columns with equal width */
        gap: 10px; /* Gap between grid items */
        span {
            justify-self: end;
        }
    }
}