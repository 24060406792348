.TextFormInput-wrapper {
    width: 100%;
    .TextFormInput {
        width: 100%;
        position: relative;
        .ClearIcon {
            position: absolute;
            z-index: 100;
            right: 8px;
            top: 20px;
            opacity: .4;
            width: 16px;
            cursor: pointer;
        }
        .TextField {
            width: 100%;
        }
    }
}