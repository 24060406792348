.GroupScores {
    .GroupScoreTable {
        .Divisions {
            position: relative;
            border: 1px solid lightgray;
            max-width: 140px;
            padding: 2px;
            ul {
                min-height: 60px;
                cursor: pointer;
                max-height: 200px;
                overflow: auto;
                padding: 8px;           
                list-style-type: decimal;
                li {
                    font-size: small;
                }
            }
            .header {
                font-weight: bold;
                position: absolute;
            }
        }
    }
}