$joiner_color: 1px solid black;

.headingWrapper {
  display: flex;
  margin-bottom: 2em;
  align-items: center;
  gap: 1em;

  :global {
    h2 {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
}

.filter {
  width: 0px;
}

.filters {
  display: flex;
  gap: 1em;
  margin-bottom: 3em;
  align-items: center;
}

.bracketTypeWrapper {
  margin: 2em 0;
  background: var(--module-background-color);
  border-radius: var(--border-radius);
  padding: 1em;
}

.bracketInfo {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 2em 0;
  background: var(--module-background-color);
  border-radius: var(--border-radius);
  padding: 2em 1em;
}

.bracketInfoItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
  :global {
    .value {
      //font-size: 1.25em;
    }
    label {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 80%;
      letter-spacing: 1px;
      opacity: 0.4;
    }
  }
}

.bracketHeader {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 1em;
  margin-bottom: 1em;
  :global {
    .roundName {
      font-weight: bold;
      font-size: 90%;
      opacity: 0.5;
    }
  }
}

.bracket .column:first-child, .bracket_consolidation .column:first-child{
  .competitor_wrapper {
    .border_join,  .border_center_top{
      border: 0px;
    }
  }
}

.Draw {
  border-radius: var(--border-radius);
  border: $joiner_color;
  padding: 20px;
  margin-bottom: 20px;
}

.bracket, .bracket_consolidation {
  display: flex;
  gap: 1em;
  flex-wrap: nowrap;
  padding: 20px;
  margin-bottom: 20px;

  transform: scale(1.00);
  transform-origin: top left;
  .column {
    position: relative;
  }
  .topHalf {
    height: 100%;
  }
}

.loadingSpinner {
  margin: 2rem auto;
  height: 2rem;
  width: 2rem;
  display: block;
}

.border_join {
  margin-left: -1em;
  width: 0.5em;
  position: absolute;
  border-bottom: $joiner_color;
  border-top: $joiner_color;
  border-right: $joiner_color;
}

.border_center_top {
  border-bottom: $joiner_color;
}

.border_center {
  width: 0.5em;
  margin-left: -0.5em;
  display: grid;
}
  
.competitor_wrapper {
  display: flex;
  flex-wrap: nowrap;
  
}

.column {
  padding-top: 26px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 1em;

  //@for $i from 1 through 10 {
  //  &:nth-child(#{$i}) {
  //    :global {
  //      .competitor {
  //        &:before {
  //          height: ($i - 1) * 125%;
  //        }
  //      }
  //    }
  //  }
  //}
}



.topHalf,
.bottomHalf {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 50%;
  gap: 1.5em;
}

.winner {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.emptyCompetitor {
  padding: 1em;
}

.button {
  display: flex;
  align-items: center;
}

.buttons {
  display: flex;
  margin: 10px 0;
}

.bracketType {
  margin-left: 20px;
}

.divisionNameWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  margin-bottom: 1em;
  :global {
    h2 {
      margin-bottom: 0;
    }
  }
}

.BracketRoundRobin {
  display: flex;
  flex-wrap: wrap;
}

.RoundFilter, .DrawFilter {
  margin-bottom: 20px;
}
// div {
//   border: 1px solid black;
// }

