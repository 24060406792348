.DivisionRegistration {
    .filter-chips {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        .yours {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 16px;
            .info-wrapper {
                display: flex;
                flex-direction: column;
                background-color: white;
                justify-content: unset;
                .your-info {
                    margin-left: 10px;
                    margin-bottom: 20px;
                    .gym-represent, .team-represent {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        align-items: baseline;
                        gap: 10px;
                        .gym-name, .team-name {
                            display: flex;
                            flex-direction: row;
                            gap: 8px;
                        }
                        b {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            .second-word {
                                margin-left: 10px;
                            }
                        }
                    }
                }
            }
            .complete-registration {
                display: flex;
                flex-direction: column;
                gap: 10px;
                .in-cart {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    gap: 10px;
                }
                .cart-button {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    @media (max-width: 575px) {
                        flex-wrap: wrap;
                    }
                    button {
                        max-width: 300px;
                    }
                    span {
                        width: 100%;
                        min-width: 140px;
                        text-align: center;
                        color: blue;
                        font-weight: bold;
                        text-decoration: underline;
                    }
                }
            }
            .complete-registration {
                border: 1px rgba(128, 128, 128, 0.548) solid;
                border-radius: 8px;
                padding: 10px;
            }
            margin-bottom: 20px;
        }
        .all {
            margin-top: 10px;
            margin-bottom: 20px;
            .title {
                margin-bottom: 20px;
            }
        }
        .title-wrapper {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            .option {
                margin-left: 10px;
                cursor: pointer;
                font-size: smaller;
            }
            .title {
                text-decoration: underline;
                font-weight: bold;
            }
        }

        [class^="FilterChips"] {
            margin-left: 10px;
        }
    }
    .divisions {
        position: relative;
        .count {
            position: absolute;
            color: blue;
            top: -2px;
        }
        .panes {
            padding: 20px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }
    }
    .EditRegistration {
        .no-changeable-found, .form {
            display: flex;
            justify-content: center;
            flex-direction: column;
            span {
                text-align: center;
                color: blue;
            }
        }
        
    }
    .DivisionItem.incart {
        background-color: rgba(127, 255, 212, 0.13);
    }
    .DivisionItem {
        max-width: 300px;
        min-width: 300px;
        p, .divisionInfo-wrapper {
            width: 100%;
        }
        .division-update{
            position: relative;
            span.incart {
                bottom: 2px;
                position: absolute;
                display: flex;
                font-size: small;
                font-style: italic;
                font-weight: bold;
                color: blue;
                width: 100%;
                justify-content: end;
            }
            span.status {
                font-weight: bold;
                // text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
            }
            span.status.Active {
                color: var(--ion-color-primary)
            }
            span.status.Pending {
                color: rgb(228, 149, 2);
            }
            .getButton {
                .change-dates {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
        .next-fees {
            .next-fee {
                display: flex;
                justify-content: center;
                padding: 2px;
                span {
                    margin-right: 4px;
                    font-size: x-small;
                }
                .next-fee-name, .next-fee-amount {
                    font-weight: bold;
                    text-decoration: underline;
                }
                .next-fee-date {
                    font-style: italic;
                }
            }
        }
        .ComboInfo {
            display: flex;
            flex-direction: column;
            .head {
                font-size: small;
                font-style: italic;
                text-decoration: underline;
                color: rgb(228, 149, 2);
            }
            .combos {
                margin-top: 0px;
                .division {
                    font-size: small;
                    font-style: italic;
                    gap: 4px;
                }
            }
            span.separator {
                color: black;
                padding: 4px;
            }
        }
    }
    @media (max-width: 575px) {
        .divisions {
            margin-bottom: 80px;
        }
    }
}

.ChangeGym, .ChangeTeam {
    // position: absolute;
    // right: 2px;
    width: 180px;
    color: blue;
    font-size: smaller;
    text-decoration: underline;
    font-style: italic;
    border-radius: 8px;
    border: 1px solid blue;
    padding: 4px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .buttons {
        display: flex;
        flex-direction: row;
    }
}

.ChangeGym.form, .ChangeTeam.form {
    width: 100%;
}