
.BracketWrapper {
    display: flex;
    flex-direction: column;
    .export {
        display: flex;
        gap: 4px;
        .pdf {
            display: flex;
            align-items: baseline;
        }
        label span{
            font-size: x-small;
            margin-left: 0px;
        }
        .mail-merge{
            height: 24px;
        }
        .ConsolidationBracket.large-round {
            .Competitor{
                min-width: 14em;
                min-height: 72px;
                max-height: 72px;
            }
        }
    }
    .ExportAll {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        border-radius: 4px;
        .buttons {
            display: flex;
            flex-direction: row;
            .export {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-right: 10px;
                @media screen and (max-width: 575px) {
                    .mail-merge {
                        width: 100%;
                    }
                }
            }
            .Orientation {
                margin-left: 20px;
                svg {
                    color: black;
                }
            }
        }
        @media screen and (max-width: 575px) {
            .buttons {
                align-items: start;
            }
        }
    }
    .ThirdPlace {
        .third_bracket_main {
            overflow-x: auto;
            overflow-y: hidden;
        }
    }
    .ThirdPlace.export {
        display: flex;
        flex-direction: column;
        border: none;
        .third_bracket_main {
            min-width: 1363px;
            overflow-x: hidden;
            overflow-y: hidden;
        }
        .title {
            margin: unset;
            padding-top: 40px;
        }
    }

    .BracketRoundRobin.export {
        border: none;
        .Summary {
            h4 {
                svg {
                    display: none;
                }
            }
        }
    }
    .BracketRoundRobin {
        display: flex;
        flex-direction: column;
        gap: 40px;
        border: 1px gray solid;
        border-radius: var(--border-radius);
        padding: 20px;
        .Summary {
            margin: 20px;
            margin-right: 40px;
            .TableContainer {
                padding: 20px;
            }
            h4 {
                display: flex;
                flex-direction: row;
                align-items: center;
                svg {
                    cursor: pointer;
                    border: 0px solid rgba(128, 128, 128, 0.26);
                    border-radius: 50%;
                    padding: 2px;
                }
            }
        }
        .button.Expand-All {
            max-width: 100px;
        }
        .RoundRobin {
            display: flex;
            flex-direction: column;
            overflow-x: auto;
            .round-header {
                display: grid;
                grid-template-columns: 50% 50%;
                .title {
                    display: flex;
                    flex-direction: row;
                    .round-title {
                        text-wrap: nowrap;
                    }
                }
                .winner-header {
                    text-align: right;
                }
            }
            .round {
                display: flex;
                flex-direction: column;
                .competitors {
                    display: flex;
                    flex-direction: column;
                    .match {
                        display: flex;
                        flex-direction: row;
                        gap: 10px;
                        .entry {
                            position: relative;
                        }
                    }
                }
            }
        }
    }

    .winner-label, .round-label {
        position: absolute;
        z-index: 10;
        color: blue;
        top: -20px;
        white-space: nowrap;
    }

    .Bracket.export.landscape {
        .Bracket-div-wrapper{
            width: 1500px;
        }
    }
    .Bracket.export.portrait {
        .Bracket-div-wrapper{
            width: 1053px;
        }
    }

    .Bracket.export {
        .bracket_main_wrapper {
            overflow: hidden;
        }
    }
    
    .Bracket {
        position: relative;
        display: flex;
        flex-direction: column;
        .competitor-info {
            .gym-name {
                font-size: small;
                font-weight: normal;
                white-space: nowrap; /* Prevents text from wrapping */
                overflow: hidden; /* Hides overflowed text */
                text-overflow: ellipsis; /* Adds the ellipsis */
                width: 200px; /* Set a width to constrain the text */
                display: block; /* Ensure it applies to inline elements like span */
            }
        }
        .bracket_main_wrapper {
            width: 100%;
            overflow-x: auto;
            overflow-y: hidden;
        }
        .has-third {
            display: flex;
            align-items: center;
            flex-direction: row;
            flex-wrap: nowrap;
            color: blue;
            gap: 10px;
            padding: 10px;
        }
        .Bracket-div-wrapper {
            overflow-x: inherit;

        }
        .bracket_main {
    
        }
        .PrintTitle.export {
            display: grid;
        }
        .PrintTitle {
            width: 100%;
            // display: none;
            grid-template-columns: repeat(2, 1fr);
            .division {
                .tournament-name {
                    font-size: smaller;
                }
            }
            .banner {
                display: flex;
                flex-direction: column;
            }
            .page {
                text-align: right;
                font-weight: bold;
            }
            .GIHBanner{
                text-align: right;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                .Logo{
                    padding: 1px;
                    width: 149px;
                    height: 56px;
                }
            }
        }
        .header-wrapper {
            .header {
                .bracket-title {
                    .Link {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        gap: 10px;
                    }
                }
            }
        }
        .registrant.block {
            color: red;
        }
        .competitor.highlite{
            background-color: aqua;
        }
        
        .competitor_wrapper.export {
            .competitor {
                border: black solid 1px;
                width: 200px;
                max-height: 90px;
                .competitor-info {
                    .competitor-name {
                        font-size: large;
                        font-weight: bold;
                        .empty {
                            font-weight: normal;
                            font-size: medium;
                        }
                    }
                    .gym-name {
                        
                    }
                }
                .competitor-name, .gym-name {
                    overflow: hidden;
                    white-space: nowrap; 
                    text-overflow: ellipsis; 
                }
            }
        }
        .competitor-name, .gym-name {
            width: 200px;
            overflow: hidden;
            white-space: nowrap; 
            text-overflow: ellipsis; 
        }
        
        .win-by {
            color: green;
            font-style: italic;
            font-size: smaller;
            text-decoration: underline;
        }

        .SelectFormInput.competitor {
            .MuiFormControl-root {
                width: 100%;
            }
        }
        .SelectFormInput.winBy {
            width: 100%;
            div{
                width: 100%;
            }
            b {
                color: blue;
            } 
        }
        .EditingCompetitor {
            .ScoreCard {
                position: fixed;
            }
            .scorecard {
                position: absolute;
                z-index: 10;
                top: 0px;
                right: 0px;
                color: green;
                .icon {
                    cursor: pointer;
                }
            }
            .info {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 4px;
                .node-id, .placement, .goto-registration {
                    font-size: x-small;
                    color: blue;
                }
                .node-id {
                    color: gray;
                }
                .goto-registration {
                    cursor: pointer;
                }
                .placement {
                    font-weight: bold;
                    color: green;
                }
            }
            .select-competitor {
                .win-by {
                    cursor: pointer;
                    z-index: 10;
                    margin-left: 10px;
                }
            }
        }

        .buttons.edit {
            display: flex;
            .SelectFormInput.bracket_type {
                min-width: 240px;
            }
        }

        .BracketDoubleElimation {
            .FinalDoubleElimination.export {
                border: none;
            }
            .FinalDoubleElimination {
                padding: 20px;
                border: 1px gray solid;
                border-radius: var(--border-radius);
                display: flex;
                flex-direction: column;
                gap: 20px;
                h5 {
                    margin-bottom: 20px;
                }
                .Pairs {
                    .Pair {
                        position: relative;
                        display: flex;
                        flex-direction: row;
                        gap: 10px;
                        margin-top: 20px;
                    }
                    
                    overflow-x: auto;
                }
            }
        }
        @media screen and (max-width: 575px) {
            margin-left: 8px;
            margin-right: 8px;
            .buttons.edit {
                flex-wrap: wrap;
                gap: 10px;
            }
        }
    }
}

.data-not-save, .bracket-size-has-changed {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 200px;
    span {
        width: 100%;
    }
    .buttons {
        position: absolute;
        bottom: 0px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        button {
            width: 100%;
        }
    }
}