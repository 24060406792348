.form {
  max-width: var(--page-max-width);
  margin: 3em auto;
  padding-bottom: 150px;
  display: grid;
  grid-template-columns: 30% 70%;
  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
}

.sub_form {
  margin: 20px;
}

.fieldDescription {
  margin-bottom: 1em;
}

.fields {
  margin-bottom: 3em;
}

.spinner {
  color: blue;
  margin: 0 auto;
  display: block;
}

.actionMenuWrapper {
  display: flex;
  justify-content: flex-end;
}

.actionsMenuIcon {
  width: 2em;
  height: 2em;
}

.GIHSubscription_index_fields{
  border: 1px gray solid;
}

.GIHSubscription_index_field {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 20px;
  margin-left: 20px;
}

ion-icon[id^=add_] {
  cursor: pointer;
}

svg.add_icon {
  color: #3880ff;
  cursor: pointer;
}

svg.delete_icon {
  color: red;
  cursor: pointer;
  margin-top: -20px;
}

svg.edit_icon {
  cursor: pointer;
}

.DiscountRules {
  display: grid;
  div {
    margin:0px;
    margin-top: 0px !important;
    margin-bottom: 0px;
  }
}
