.SupportPage {
    padding: 20px;
    margin-bottom: 20px;
    .contact {
        p {
            padding: 20px;
        }
    }

    .documentation {
        list-style-type: none; /* Remove default bullet points */
        counter-reset: my-counter; /* Initialize a custom counter */

        /* Style for list items */
        li {
            counter-increment: my-counter; /* Increment the custom counter for each list item */
            margin-bottom: 10px; /* Add some spacing between items */
        }

        /* Style for the number alongside list items */
        li::before {
            content: counter(my-counter); /* Display the counter value */
            display: inline-block;
            width: 20px; /* Adjust the width as needed */
            text-align: right; /* Right-align the number */
            margin-right: 10px; /* Add spacing between the number and text */
            font-weight: bold; /* Make the number bold, if desired */
        }

        .pdf {
            overflow: auto;
            display: flex;
        }
        .page {
            margin: 20px;
            height: 820px;
            .textLayer, .annotationLayer {
                display: none;
            }
        }
        .help{
            display: flex;
            flex-direction: column;
            margin: 20px;
        }
    }
}