//.errorMessage {
//  position: absolute;
//  top: calc(100% + 0.5rem);
//  left: 0;
//}
//
//.fieldWrapper {
//  display: flex;
//  align-items: center;
//  margin-bottom: 1.5rem;
//  width: 100%;
//}
//
//.inputWrapper {
//  width: 100%;
//  position: relative;
//}

.hiddenInput {
  display: none;
}