.TournamentInformationForm{
    button {
        max-width: 180px;
    }
    .icon_button{
        width: 40px;
        height: 40px;
    }
    .Publish {
        display: flex;
        flex-direction: column;
        .competitors {
            .registration-columns {
                margin-left: 20px;
                span {
                    font-size: small;
                    font-style: italic;
                    color: gray;
                }
            }
        }
    }
    .TShirt {
        .DateFormInput1 {
            .picker {
                .DatePicker {
                    width: 300px;
                }
            }
        }
        .Size {
            border: 1px rgba(128, 128, 128, 0.2) solid; 
            padding: 6px;
            border-radius: 6px;
        }
    }
    .important-dates {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .SelectFormInput.status{
        width: 160px;
    }
    .Locations {
        display: grid;
        .location {
            .AddressForm {
                .SelectFormInput.state,  .SelectFormInput.country {
                    max-width: 400px;
                }

            }
        }
    }
    .general-information {
        position: relative;
        .tournament-path, .tournament-rule {
            position: relative;
            display: flex;
            align-items: center;
        }
        .check-url {
            position: absolute;
            right: 4px;
            top: 54px;
            z-index: 10;
            color: blue;
            cursor: pointer;
            text-decoration: underline;
            font-size: small;
            font-style: italic;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }

    .RoundRobinPoints{
        display: flex;
        flex-direction: column;
    }
    .TextFormInput{
        margin-bottom: 20px;
    }

    @media (max-width: 575px) {
        margin-left: 8px;
        margin-right: 8px;
        form {
            .TextFormInput{
                margin-right: 4px;
                max-width: 90vw;
                overflow: hidden;
                div {
                    max-width: 90vw;
                }
            }
            .MultiSelectFormInput-wrapper {
                width: 92%;
            }
            .SelectFormInput.currency, .SelectFormInput.time_zone, .MultiSelectFormInput.restricted_to_teams{
                width: 92% !important;
            }
            .general-information {
                .tournament-path, .tournament-rule {
                    width: 90%;
                }
                button {
                    position: absolute;
                    right: 24px;
                }
            }
            .Locations {
                .location {
                    .AddressForm {
                        .SelectFormInput.state,  .SelectFormInput.country {
                            max-width: 90%;
                        }
                    }
                }
            }
            .TextAreaFormInput.waiver {
                width: 90%;
            }
        }
    }
}
