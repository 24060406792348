.PaymentInfos {
    .payment-name {
        margin-bottom: 14px;
    }
    
    .SelectFormInput.payment_type{
        width: 200px;
        margin-top: 20px;
    }
    .remove-button-wrapper{
        height: 20px;
    }

    .TextAreaFormInput {
        .MuiFormControl-root {
            textarea {
                width: 90% !important;
            }
        }
    }

    @media (max-width: 575px) {
        .TextAreaFormInput {
            .MuiFormControl-root {
                textarea {
                    width: 80% !important;
                }
            }
        }
        > * {
            width: 100%;
            div {
                width: 100%;
            }
        }
        
    }
}
