.coverPhoto {
    width: 100%;
    height: 25vh;
    // object-fit: cover;
    //border-radius: 10px;
}

.header {
  text-align: center;
  margin: 1em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
  i {
    margin-right: 0.5em;
  }
}

.headerInfo {
  display: flex;
  align-items: center;
}

.infoSection {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1em;
  padding: 1em;
  margin: 3em auto;
  max-width: var(--page-max-width);
  background: var(--module-background-color);
  border-radius: var(--border-radius);
  box-sizing: border-box;
  @media (max-width: 575px) {
    grid-template-columns: 1fr;
  }
  :global {
    .icon {
      filter: invert(1);
    }
  }
}

.info {
  padding: 0.5em;
  display: flex;
  gap: 1em;
  :global {
    .icon {
      font-size: 1.25em;
      padding-top: 2px;
    }
    span {
      display: block;
      &:first-child {
        font-weight: bold;
      }
    }
  }
}

.badge {
  background: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  border-radius: 5px;
  padding: 3px 10px;
  font-weight: bold;
  font-size: 0.8em;
  margin: 0.5em;
  display: inline-block;
}

.tournamentName {
  margin: 0;
  @media screen and (max-width: 575px) {
    font-size: 1.5em;
  }
}

.section {
  // height: 100vh;
  //width: 100%;
  background: var(--ion-toolbar-background);
  border-radius: 1rem;
  padding: 2rem;
  margin-top: 1rem
}

.content {
  scroll-behavior: smooth;
}

.divisions {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1em;
  @media screen and (max-width: 575px) {
    grid-template-columns: 1fr;
  }
  margin-bottom: 5vh;
}

.filters {
  display: flex;
  align-items: center;
  margin-bottom: 2em;
  :global {
    .filter {
      margin-right: 0.5em;
    }
  }
}

.division {
  background: var(--module-background-color);
  padding: 1em;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.1em;
  :global {
    .button {
      margin: 2px auto;
      display: block;
    }
  }
}

.divisionName {
  font-weight: bold;
  margin: 0;
  text-decoration: underline;
  margin-bottom: 4px;
}

.divisionInfo {
  margin: 0.1em 0;
  display: grid;
  grid-template-columns: auto auto;
  gap: .1em;
  :global {
    p {
      margin: 0;
    }
    .value {
      text-align: right;
    }
  }
  b {
    white-space: nowrap;
  }
}

.divisionPrice {
  font-weight: bold;
  text-align: center;
  margin: 0 0 0.5em 0;
}

.filterWrapper {
  display: flex;
  align-items: center;
  gap: 0.5em;
  margin-bottom: 2em;
}

.avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  div {
    width: 6vw;
    height: 6vw;
    max-width: 100px;
    max-height: 100px;
    border-radius: 50%;
    overflow: hidden;
    img {
      z-index: 100;
      object-fit: cover;
      height: inherit;
    }
  }
  span, b {
    white-space: wrap;
    text-align: center;
  }
}

.DivisionFilter {
  margin: 20px;
  max-width: 640px;
}

.DivisionFilterButtons {
  margin-bottom: 20px;
}

.Registrations {
  h2 {
    margin-bottom: 20px;
  }
}