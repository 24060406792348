.page {
  //padding: 1.5rem 1.5rem 2.5rem 1.5rem;
  //@media screen and (max-width: 500px) {
  //  padding: 1.5rem 1.5rem 9.5rem 1.5rem;
  //}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: c;
  // margin: .8em;
}

.pageHeader {
  height: 200px;
  position: sticky;
  top: 0;
  overflow: hidden;
  z-index: 100;
  margin-bottom: 0.5rem;
  &:after {
    content: '';
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, #1B202C 0%, rgb(41 45 49) 70%);
    opacity: 0.6;  
  }
  @media screen and (max-width: 575px) {
    // padding: 8vh;
  }
}

.pageHeader2 {
  position: relative;
  @media screen and (max-width: 575px) {
    margin-bottom: 14vw;
  }
}

.pageHeaderImage {
  height: 14vh;
  object-fit: cover;
}

.pageHeaderTitleWrapper {
  position: absolute;
  left: 0;
  top: 0;
  padding: 1rem;
  margin: 0;
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
}

.pageTitle {
  padding: 1rem 2rem 0 2rem;
  margin: 0;
}

.pageHeaderTitle {
  margin: 0 0 0.5rem 0;
}

.pageHeaderSubtitle {
  margin: 0;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  @media screen and (max-width: 575px) {
    grid-template-columns: 1fr;
  }
}

.gridTile {
  background: #eee;
  padding: 1rem;
  border-radius: 1rem;
}

.gridImage {
  height: 40px;
  width: 40px;
  background-size: contain;
}

.pageHeaderCoverImage {
  width: 100%;
  height: 30vh;
  object-fit: cover;
}

.pageHeaderProfileImage {
  height: 15vw;
  width: 15vw;
  // border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
  position: absolute;
  bottom: -0.5vw;
  z-index: 0;
  left: 0;
  margin: 0 1rem;
  @media screen and (max-width: 575px) {
    height: 30vw;
    width: 30vw;
    bottom: -15vw;
  }
}

.pageContentInfo {
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 2rem 1rem;
  font-size: 1.1rem;
  margin: 1rem 0 4rem;
  @media screen and (max-width: 575px) {
    font-size: 1rem;
    grid-template-columns: 1fr 1fr;
  }
}

.pageContentInfoLabelWrapper {
  margin-bottom: 1rem;
  display: flex;
  opacity: 0.5;
}

.pageContentInfoItem {
  padding: 0;
  display: grid;
  grid-template-rows: 2em auto;
  grid-template-columns: 1em auto;
  gap: 0.5rem 1em;

  //@media screen and (max-width: 575px) {
  //  display: flex;
  //  flex-direction: row;
  //  justify-content: flex-start;
  //}
}

.pageContentInfoIcon,
.pageContentInfoLabel {
  font-size: 0.9em;
  opacity: 0.5;
}

.pageContentInfoLabel {

}

.pageContentInfoValue {
  font-weight: 400;
  grid-column: 2 / span 1;
}

.pageContent {
  // margin: 2em 2em 20vh 2em;
}

.listImage {
  width: 5vw;
  height: 5vw;
  margin: 1rem 0;
  min-width: 50px;
  min-height: 50px;
}

.listContent {
  padding: 1rem;
  :global {
    h5 {
      margin: 0 0 0.5rem 0;
    }
    p {
      margin: 0 0 0.5rem 0;
    }
  }
}

.placeholderImage {
  width: 100%;
  height: 100%;
  background: #17191c;
}

.marginBottom {
  max-width: var(--page-max-width);
  margin: 1em auto;
  margin-bottom: 10vh;
}

.floatingMessage {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 10px;
  transition: opacity 0.3s;
  white-space: nowrap;
}

.send_message {
  transform: rotate(310deg);
}

.create_button {
  margin-bottom: 10px;
}

.editable {
  cursor: pointer;
}

.alignCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
}