.SignUpForm {
  .form {
    margin-bottom: 100px;
    .page {
      max-width: 520px;
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 16px;    
      .primary-gym-wrapper, .primary-team-wrapper {
        width: 100%;
      }
    }
  }

  .errorMessage {
    position: absolute;
    top: calc(100% + 0.5rem);
    left: 0;
  }

  .fieldWrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  .inputWrapper {
    width: 100%;
    position: relative;
  }

  .buttonWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    .cancel {
      margin-left: 10px;
    }
  }

  .fieldFlow {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: center;
    .age, .weight, .gender{
      div {
        margin-bottom: 0px !important;
      }
      width: 120px;
    }
    .gi_level {
      width: 180px;
    }
    .SelectFormInput.weight_unit{
      width: 80px;
      margin-bottom: 0px;
    }
  }

  .country {
    width: 300px;
  }

  .waiver {
    margin: 40px;
  }

  .username {
    .username-wrapper {
      display: flex;
      align-items: center;
      position: relative;
      .check-username {
        position: absolute;
        right: 4px;
        bottom: 4px;
        z-index: 10;
        font-size: x-small;
        .CheckUserNameIcon {
          text-decoration: underline;
          cursor: pointer;
          display: block;
          white-space: nowrap;
          color: blue;
        }
      }
    }
    .note {
      font-style: italic;
      font-size: x-small;
      margin-left: 12px;
      color: blue;
      margin-top: -2px;
      margin-bottom: 10px;
    }
  }

  @media screen and (max-width: 575px) {
    .name, .username-email, .password {
      width: 100%;
      display: grid;
      .TextFormInput {
        .MuiFormControl-root {
          width: 100%;
        }
      }
    }

    .username-email {
      .username {
        .MuiFormControl-root {
          width: 100%;
        }
      }
    }

  }
}