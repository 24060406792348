.HomePage {
  .image {
    width: 20%;
    height: 100%;
  }

  .header {
    padding: 0 20px;
    //font-family: 'Evogria', sans-serif;
  }

  .card {
    height: 20vh;
    background: rgba(255,255,255,0.05);
    img {
      height: 5vh;
    }
  }

  .listItem {
    padding: 1.5rem 0;
  }

  .swiperContainer {
    padding-bottom: 20px;
    .swiper-pagination-bullets {
      bottom: 0;
    }
  }

  .newsList {
    margin-bottom: 5rem;
  }

  .loadingImage {
    height: 200px;
  }

  .loadingH2 {
    width: 80vw;
    height: 2rem;
  }

  .loadingH3 {
    width: 50vw;
    height: 1.2rem;
  }

  .loadingP {
    width: 30vw;
    height: 1rem;
  }

  .loadingA {
    width: 10vw;
    height: 1rem;
  }
}