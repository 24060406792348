.Help {
    position: relative;
    background-color: lightyellow;
    height: 100%;
    width: 300px;
    display: flex;
    flex-direction: row;
    color: black;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    a {
        cursor: pointer;
        color: blue;
    }
    .main {
        width: 100%;
        .body {
            height: 100%;
            .title {
                padding: 10px;
            }
            ul {
                li{
                    .Link {
                        cursor: pointer;
                    }
                }
            }
            .help-content {
                overflow-y: auto;
                height: 100%;
                padding-right: 20px;
                padding-left: 20px;
            }
        }
        .buttons {
            padding-top: 0px;
            background-color: inherit;
            right: 4px;
            top: 2px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;;
            align-items: center;
            font-size: small;
            font-style: italic;
            text-decoration: underline;
            .close, .home {
                cursor: pointer;
                svg {
                    border: 1px solid red;
                    border-radius: 50%;
                }
            }
        }
    }
    
    .resize-divider{
        width: 6px;
        background-color: gray;
        cursor: ew-resize;
    }
    @media screen and (max-width: 575px) {
        width: 100%;
    }
}