.MessagesPage, .EntityMessagesPage {
    @media (max-width: 575px) {
        width: 100%;
        .MessageThreadName {
            display: flex;
            flex-direction: column;
            .DateRange {
                .rdrDefinedRangesWrapper, .rdrDateDisplayWrapper {
                    display: none;
                }
            }
            .last-messsage-on {
                display: flex;
                flex-wrap: nowrap;
            }
        }
    }
    padding: 20px;
    height: 100%;
    overflow-y: auto;
    .SendMessage, .SendMessage-button {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    
    .MessageThreadName {
        height: 40px;
        margin-left: auto;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        .last-message-on {
            position: absolute;
            right: 8px;
            top: -2px;
        }
        .name, svg {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 20px;
            position: relative;
            height: 100%;
            .Badge {
                .thread-name {
                    white-space: normal; 
                }
            }
            .profile-link {
                cursor: pointer;
                font-size: x-small;
                font-weight: normal;
                font-style: italic;
                position: absolute;
                right: 0px;
                top: -14px;
            }
        }
        .toPDF {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: right;
            svg {
                margin-left: auto;
                border: 1px solid gray;
                border-radius: 50%;
                padding: 1px;
                cursor: pointer;
            }
            .DateRange {
                overflow-x: auto;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: right;
                margin-left: auto;
            }
            .PdfHTML {
                overflow-x: auto;
                max-width: 1353px;
                min-width: 1353px;
                display: block;
                .GIHBanner {
                    display: flex;
                    flex-direction: row;
                    align-items: baseline;
                    .Logo{
                        padding: 1px;
                        width: 149px;
                        height: 56px;
                    }
                }
                .line.title {
                    font-weight: bold;
                    font-style: italic;
                    text-decoration: underline;
                }
                .line.body {
                    font-weight: normal;
                }
            }
        }
    }

    .MessageHeader {
        button {
            margin-bottom: 10px;
            margin-top: 10px;
        }
        .receipent {
            min-width: 400px;
        }
        .TextFormInput-wrapper.sendMessage {
            width: 100%;
        }
    }
    .date {
        color: blue;
        text-decoration: underline;
        font-size: x-small;
        font-weight: normal;
        white-space: nowrap;
    }
    .MessageThreads {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .sender {
            display: flex;
            flex-direction: row;
            position: sticky;
            position: -webkit-sticky; /* For Safari */
            position: sticky;
            top: 0; 
            z-index: 100; 
            .from {
                margin-left: 0px !important;
            }
        }
        .incoming, .outgoing {
            overflow-y: auto;
            max-height: 300px;
            min-height: 40px;
            width: 80%;
        }
        .incoming {
            margin-bottom: 10px;
            background-color: rgba(0, 161, 251, 0.25);
        }

        .outgoing {
            margin-left: auto;
            margin-bottom: 10px;
            background-color: rgba(251, 251, 0, 0.096);
        }
        
        .buttons {
            position: sticky;
            bottom: 0;  /* Align to the bottom */
            right: 0;   /* Align to the right */
        }
        .ForwardMessage {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }

    .send_message {
        transform: rotate(330deg);
    }
    .messages {
        overflow: auto;
        .RenderSend {
            margin-bottom: 10px;
            width: 100%;
        }
        .MessageThreads {
            .content {
                display: flex;
                flex-direction: column;
                padding: 14px;
                .content-text {
                    white-space: pre-line;
                }
                
                .buttons, .date {
                    margin-left: auto;
                    display: flex;
                    flex-direction: row;
                    gap: 4px;
                }
                svg {
                    border: 1px solid gray;
                    border-radius: 50%;
                    padding: 1px;
                    cursor: pointer;
                }
            }
        }
        .thread {
            display: flex;
            flex-direction: row;
            gap: 20px;
            width: 100%;
        }
    }

    @media screen and (max-width: 575px) {
        padding: 6px;
        .sendMessageWrapper {
            margin-bottom: 20px;
            .receipent, .RenderSend {
                div {
                    margin: 0px;
                }
            }
        }
        .inbox_header {
            padding: 0px;
            .receipent {
                min-width: unset;
                width: 100%;
            }
            .TextFormInput-wrapper.sendMessage {
                width: 100%;
                .css-1wmzsjq-MuiFormControl-root-MuiTextField-root {
                    width: 100%;
                }
            }
        }
        .messages {
            .RenderSend {
                .TextFormInput-wrapper.sendMessage {
                    .TextFormInput.sendMessage {
                        display: flex;
                        width: 100%;
                        flex-grow: 1;
                        .MuiFormControl-marginNormal.MuiTextField-root.css-1wmzsjq-MuiFormControl-root-MuiTextField-root{
                            width: 100%;
                            min-width: unset;
                        }
                    }
                }
            }
        }
    }

}

.ThreadFilter {
    max-width: 400px;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 10px;
    margin-left: auto;
    .helper {
        position: absolute;
        right: 12px;
        bottom: 10px;
        opacity: .7;
        font-size: x-small;
        font-style: italic;
        text-decoration: underline;
        color: blue;
    }
    @media screen and (max-width: 575px) {
        width: 96%;
        margin-left: 2px;
    }
}