.filters {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  align-items: center;
  :global {
    .icon {
      height: 1.5em;
      width: 1.5em;
    }
  }
}

.inline {
  margin-bottom: 0;
}

.filter {
  font-weight: 600;
  border-radius: 20em;
  padding: 0.25em 1em;
  border: 1px solid var(--ion-text-color);
  cursor: pointer;
  white-space: nowrap;
  :global {
    input {
      display: none;
    }
  }
}

.activeFilter {
  background: var(--ion-text-color);
  color: var(--ion-background-color);
}
