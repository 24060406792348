.LoginPage {
  .page {
    margin: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
  }

  .title, .sign-up {
    text-align: center;
    //font-family: 'Evogria', sans-serif;
    margin-top: 2rem;
    margin-bottom: 4rem;
  }
  .sign-up, .forget-password, .login-link {
    font-size: .9em;
    font-weight: bold;
  }
  .forget-password, .login-link  {
    margin-left: 10px;
  }

  .divider {
    margin: 1rem 0;
    text-align: center;
    position: relative;
    &:before {
      content: "";
      display: block;
      width: calc(50% - 2em);
      height: 1px;
      background: rgba(255,255,255,0.1);
      left: 0;
      top: 50%;
      position: absolute;
    }
    &:after {
      content: "";
      display: block;
      width: calc(50% - 2em);
      height: 1px;
      background: rgba(255,255,255,0.1);
      right: 0;
      top: 50%;
      position: absolute;
    }
  }

}