.ContactInfos {
    display: grid;
    align-items: center;
    .ContactInfo {
        margin-bottom: 0px;
    }
    .remove-button-wrapper{
        height: 20px;
    }
    .contactInfoWrapper{
        width: 100%;
        // border: 1px rgba(128, 128, 128, 0.2) solid; 
        padding: 6px;
        border-radius: 6px;
    }
    .SelectFormInput {
        max-width: 240px;
        margin-bottom: 20px;
        .MuiFormControl-root {
            margin-top: 10px !important;
        }
    }
    .name-label {
        font-size: small;
        font-weight: bold;
        text-decoration: underline;
        color: blue;
        text-align: right;
    }
    @media (max-width: 575px) {
        .contactInfoWrapper{
            width: 96%;
            padding: 4px;
        }
    }
}
