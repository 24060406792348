$colorReg1: blue;
$colorReg2: white;
$colorClock: red;
$backgroundColorClock: black;
$colorNegPoint: red;
$colorPoint2: orange;
$backgroundColorNegPoint: black;
$colorPoint: green;
$backgroundColorPoint: black;
@font-face {
    font-family: LCD2U;
    src: url("../../fonts/LCD2U.woff");
}
@keyframes blinker {
    50% {
        opacity: 0;
    }
}
.blink {
    animation: blinker 1s step-start infinite;
}
.ScoreCard-wrapper {
    cursor: default;
    height: 100%;
    .ScoreCard {
        position: relative;
        height: 100%;
        padding: 10px;
        background: linear-gradient(to right, $colorReg1 0%, $colorReg1 50%, $colorReg2 50%, $colorReg2 100%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 1.9vw;
        justify-content: center;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
        .screen-buttons {
            position: absolute;
            top: 0px;
            right: 0px;
            width: 60px;
            display: flex;
            flex-direction: row;
            .FullScreenIcon, .FlipRegIcon {
                color: blue;
                width: 30px;
                height: 30px;
                cursor: pointer;
            }
        }
        .division {
            font-size: 3em;
            margin-bottom: 10px;
            font-weight: bold;
            text-decoration: underline;
            color: lightseagreen;
        }
        
        .win-by {
            position: fixed;
        }
        .buttons {
            position: absolute;
            cursor: pointer;
            position: fixed;
            padding-left: 10px;
            color: greenyellow;
            .button {
                margin-right: 20px;
            }
        }
        .scorecard-competitors,
        .timer,
        .neg-points,
        .points,
        .division {
            width: 100% !important;
            height: auto;
            display: grid;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            // margin-bottom: 60px;
        }
        .reg1,
        .reg2 {
            white-space: nowrap;
            .gym-name {
                font-size: .5em;
                width: 100%;
                text-align: center;
            }
        }
        .reg2 {
            text-align: left;
            color: blue;
        }
        .reg1 {
            text-align: right;
            color: white;
        }
        .timer,
        .neg-points {
            grid-template-columns: 30% 40% 30%;
        }
        .scorecard-competitors,
        .points {
            grid-template-columns: 50% 50%;
        }
        .scorecard-competitors {
            font-size: 2.4em;
            margin-bottom: 20px;
            font-family: Impact, Haettenschweiler, "Franklin Gothic Bold", "Arial Black", sans-serif;
            .reg-wrapper {
                z-index: 1000;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;
                padding: 6px;
                .select-winner {
                    font-weight: normal;
                    font-size: .3em;
                }
                .reg1 {
                    cursor: pointer;
                    padding-right: 20px;
                    color: $colorReg2;
                }
                .reg2 {
                    cursor: pointer;
                    padding-left: 20px;
                    color: $colorReg1;
                }
                [name="winner"] {
                    width: 1.2em !important;
                }
            }
        }
        .timer,
        .neg-points,
        .points {
            font-family: "LCD2U", monospace;
        }
        .timer {
            .flag-holder {
                display: flex;
                width: 100%;
            }
            ion-icon {
                height: 80%;
                width: 50%;
                margin: auto;
                float: left;
            }
            .clock {
                position: relative;
                font-family: "LCD2U", monospace;
                font-size: 10vw;
                color: $colorClock;
                background-color: $backgroundColorClock;
                margin-top: auto;
                margin-bottom: auto;
                white-space: nowrap;
            }
        }
        .neg-points {
            .point2 {
                color: $colorPoint2;
            }
            .point,
            .point2 {
                background-color: $backgroundColorNegPoint;
                font-size: 1.5em;
                width: 40%;
                text-align: center;
            }
            .point {
                color: $colorNegPoint;
                font-size: 1.6em;
            }
            .reg1,
            .reg2 {
                display: flex;
                height: 100%;
            }
            .reg1 {
                position: relative;
                justify-content: flex-end;
                .point,
                .point2 {
                    .buttons {
                        .atom-icon {
                            margin-right: 20px;
                            color: greenyellow;
                        }
                    }
                }
            }
            .promoter-icon {
                width: 100%;
                .atom-icon {
                    width: 3em;
                    height: 3em;
                }
            }
        }
        .points {
            .point {
                color: $colorPoint;
                background-color: $backgroundColorPoint;
                font-size: 2.5em;
                width: 40%;
                text-align: center;
            }
            .reg1 {
                .point {
                    float: right;
                }
            }
            .reg2 {
                .point {
                    float: left;
                }
            }
        }
        .flag {
            transform: rotate(90deg);
            height: 40%;
        }
        .point,
        .clock {
            border: 1px solid black;
        }

        // @media only screen and (max-width: 1400px) and (max-height: 820px) {
        //     font-size: 1.7vw;
        // }

        // @media only screen and (max-width: 1026px) and (max-height: 800px) {
        //     font-size: 2em;
        // }

        // @media only screen and (max-width: 920px) and (max-height: 420px) {
        //     font-size: 1.4em;
        // }

        // @media only screen and (max-width: 680px) and (max-height: 375px) {
        //     font-size: 1.2em;
        // }
    }
}