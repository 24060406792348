.Busy {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    z-index: 100;
    .parentWrapper {
        width: 100%;
        height: 100%;
        .overlayPane {
            background-color: gray;
            opacity: .3;
            width: 100%;
            height: 100%;
        }
        .messagePane {
            cursor: wait;
            position: fixed;
            z-index: 1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 80vw; /* 80% of the viewport width */
            max-width: 100%; /* Ensures it doesn’t exceed the viewport */
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px; /* Optional: for spacing inside */
            box-sizing: border-box; /* Ensures padding doesn't affect width */
            .content {
                position: absolute;
                color: green;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 40px;
                background-color: black;
                border-radius: 8px;
            }
            .message {
                color: white;
                margin-left: 20px;
                animation: flash 1s infinite;
            }
            .spinner {
                color: blue;
                width: 80px;
                height: 80px;
                animation: spin 1s linear infinite; /* Rotation animation */
            }
        }
    }
}

@keyframes flash {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}