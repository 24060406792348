.MembershipTypes {
    @media (max-width: 575px) {
        .membership-type {
            .text-inputs {
                display: flex;
                flex-direction: column;
                .TextFormInput.name, .TextFormInput.code{
                    .MuiFormControl-root {
                        width: 100%;
                    }
                }
            }
        }
    }
}